import React from "react";
import {Component} from 'react'
import {Row, Col} from 'react-bootstrap';

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import AblePlayerComponent from "../../Components/AblePlayerComponent"

class VideoVisiteGuideeApprocheCulturellePage extends Component {
  render() {

  var vidDesc =`
<p><strong>Aide à la navigation :</strong> La vidéo présente en alternance les propos d’un narrateur et les éléments visuels de la vidéo qui sont porteurs de contenu. Cette vidéo sert à présenter les différentes facettes du site Web de formation sur l’approche culturelle de l’enseignement en projetant l’écran d’un ordinateur et en naviguant sur le site Web pour illustrer les propos du narrateur.</p>
<p><strong>Narrateur</strong></p>
<p>Cette brève visite guidée vous donnera un aperçu du site Web de formation sur l’approche culturelle de l’enseignement.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur une page dont la bande au haut comporte le titre « Approche culturelle de l’enseignement, Développement professionnel ». Au centre de l’écran se trouve un encadré blanc avec les mots « Visite guidée ». Lorsque le narrateur prend la parole, les mots disparaissent pour laisser place au texte qu’il narre.</p>
<p><strong>Narrateur</strong></p>
<p>À partir de cette page d’accueil, vous avez accès aux trois autres composantes du Cadre d’appropriation de la culture dans les écoles de langue française : le « Document de fondements pour une approche culturelle de l’enseignement »; la Banque pancanadienne de référents culturels signifiants pour la francophonie; la Banque de stratégies pédagogiques.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur la page d’accueil du site Web, où sont affichés divers onglets. Une flèche apparaît pour désigner les onglets des trois composantes mentionnées par le narrateur.</p>
<p><strong>Narrateur</strong></p>
<p>La Banque pancanadienne de référents culturels signifiants pour la francophonie a été conçue pour appuyer la mise en œuvre d’une approche culturelle de l’enseignement dans les écoles de langue française du Canada.</p>
<p><strong>Description visuelle</strong></p>
<p>Le titre « Banque pancanadienne de référents culturels » apparaît au centre de l’écran, puis l’on se retrouve sur la page du site Web en question, qui contient plusieurs boîtes de critères de recherche à remplir. </p>
<p><strong>Narrateur</strong></p>
<p>Cette approche a pour objectif principal de mettre l’élève en contact avec la culture francophone locale, provinciale ou territoriale, régionale, pancanadienne et mondiale, et ce, sur toute la durée de ses études, en intégrant des contenus culturels signifiants à l’enseignement des diverses matières, et ce, de la maternelle à la 12e année.</p>
<p><strong>Description visuelle</strong></p>
<p>Le texte lu par le narrateur s’affiche dans un encadré à l’écran.</p>
<p><strong>Narrateur</strong></p>
<p>La Banque pancanadienne de référents culturels signifiants pour la francophonie ne prétend pas constituer un répertoire complet détaillant tout ce qui influence, marque, caractérise ou décrit la francophonie canadienne. Il est possible de faire une recherche par thématique, par discipline, par groupe d’âges, par origine ou provenance, par rayonnement et par descripteurs. On peut également faire une recherche par mot-clé, tel qu’illustré dans cet exemple. Voici un exemple de résultat possible avec la recherche de mot-clé « drapeau ». Vous voyez le titre de la fiche, un descripteur, ainsi qu’un bref aperçu de ce que contient la fiche. Voici la fiche. Il est également possible de la télécharger en format PDF et de l’imprimer.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur la page de résultats d’une recherche sur la Banque pancanadienne de référents culturels signifiants de la francophonie, puis on revient à la page de critères de recherche. Des flèches apparaissent pour désigner les divers critères que nomme le narrateur. La page des résultats de recherche du mot-clé « drapeau » apparaît. Une flèche désigne le titre de la fiche au haut de la page, « Patrimoine linguistique, historique, contemporain ». Elle désigne aussi le descripteur d’une des fiches, soit « Drapeau de la francophonie yukonnaise, Symbole », sous lequel se trouve un bref texte d’aperçu. On se retrouve ensuite sur la fiche du drapeau fransaskois. Une flèche désigne l’icône du document en format PDF au bas de l’écran.</p>
<p><strong>Narrateur</strong></p>
<p>La série de capsules vidéo sur les pratiques pédagogiques a pour but d’illustrer différentes activités d’apprentissage intégrant une approche culturelle de l’enseignement, et ce, dans différentes matières.</p>
<p><strong>Description visuelle</strong></p>
<p>Le titre « Banque de stratégies pédagogiques » apparaît au centre de l’écran, puis le texte lu par le narrateur s’affiche dans un encadré blanc. </p>
<p><strong>Narrateur</strong></p>
<p>La Banque de stratégies pédagogiques contient 18 capsules vidéo. Pour chacune d’entre elles, vous trouverez un synopsis, le verbatim, ainsi que des suggestions d’autres capsules vidéo à visionner. De plus, vous trouverez de l’information concernant la situation d’apprentissage illustrée en lien avec l’approche culturelle de l’enseignement, quelques suggestions d’adaptation de la situation d’apprentissage selon diverses thématiques. Finalement, voici quelques exemples d’exploitation d’un thème dans différentes matières.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur la page de la banque de stratégies pédagogiques, où une série de capsules vidéo sont affichées. On se retrouve ensuite sur la page de l’une des capsules vidéo. Une flèche désigne un encadré contenant le synopsis de la capsule qui se trouve au-dessus de la vidéo, puis elle désigne le mot « Verbatim » accolé au logo d’un document PDF, juste en dessous de la vidéo. Enfin, elle désigne à la droite de la vidéo une liste d’autres capsules vidéo suggérées. On fait défiler la page vers le bas pour montrer une section en dessous de la vidéo qui porte le titre « Cette situation d’apprentissage » avec une liste d’éléments reliés. L’écran change pour montrer du texte suggérant des façons d’adapter la situation d’apprentissage en question. Lorsque le narrateur dit « Finalement », on se retrouve sur une page avec le titre « Considérations pédagogiques ». Une image s’y trouve, comportant plusieurs bulles de texte et portant le titre « Exemples d’exploitation du thème des traditions culinaires à des fins diverses dans différentes matières ».</p>
<p><strong>Narrateur</strong></p>
<p>Ce site Web de développement professionnel a été conçu dans le but de faire connaître aux intervenantes et intervenants en éducation les différentes composantes du Cadre pancanadien pour l’appropriation de la culture dans les écoles de langue française. Cette formation permettra à l’apprenante ou l’apprenant, à partir des dix principes de l’approche culturelle de l’enseignement : </p>
<p>– de décrire les fondements qui sous-tendent le Cadre pancanadien pour l’appropriation de la culture dans les écoles de langue française,</p>
<p>– de comprendre le modèle de l’approche culturelle de l’enseignement et d’en interpréter ses composantes; de dégager les éléments devant appuyer la mise en œuvre de cette approche, et</p>
<p>– de maîtriser les interventions relatives aux programmes d’études et aux interventions pédagogiques devant favoriser l’application de l’approche culturelle de l’enseignement dans les écoles de langue française en contexte minoritaire.</p>
<p><strong>Description visuelle</strong></p>
<p>Le titre « Formation » apparaît au centre de l’écran, puis le texte lu par le narrateur s’affiche dans un encadré blanc au fur et à mesure qu’il parle. </p>
<p><strong>Narrateur</strong></p>
<p>Il est important de vous inscrire pour que le site puisse vous reconnaître lors d’une prochaine visite et vous permette de poursuivre votre formation. Votre inscription est aussi nécessaire pour conserver vos réponses aux différentes activités et questionnements qui vous seront proposés au fil de votre parcours de formation.</p>
<p><strong>Description visuelle</strong></p>
<p>On revient à la page d’accueil du site Web avec les divers onglets. Une flèche apparaît pour indiquer, dans la bande au haut de l’écran, deux cases de saisie : « Pseudonyme » et « Mot de passe ».</p>
<p><strong>Narrateur</strong></p>
<p>Les modules de formation ont été conçus selon les dix principes de l’approche culturelle de l’enseignement afin de vous permettre de bien comprendre la portée de chacun d’eux. Il faut toutefois se rappeler que lorsque vous les appliquez dans une réelle situation d’apprentissage avec les élèves, ces principes ne seraient pas décortiqués ainsi. En fait, la plupart du temps, vous toucherez inconsciemment à plusieurs principes lorsque vous planifierez des situations d’apprentissage qui intègrent une approche culturelle de l’enseignement.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur une page dont le titre sur l’onglet au haut est « Table des matières ». Une image s’y trouve, représentant une toile qui relie plusieurs termes entre eux, telle une constellation. Alors que parle le narrateur, une flèche désigne dix de ces termes, soit les dix principes de l’approche culturelle de l’enseignement : « Construction identitaire », « Programmes d’études », « Appropriation de la culture », « Langue et culture », « Dialogue authentique », « Héritage culturel », « Leadership culturel », « Engagement de l’élève », « Diversité culturelle », « Famille-école-communauté ».</p>
<p><strong>Narrateur</strong></p>
<p>Tous les modules de formation ont été élaborés selon une même structure. Tout d’abord, dans la section « Amorce », vous trouverez un texte de présentation du principe traité. Vous aurez aussi l’occasion de réfléchir à ce que vous savez déjà sur le sujet avant même de débuter le module.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur une page portant le titre « Appropriation de la culture », sous lequel se trouve un encadré avec le texte de présentation. On change de page et se retrouve sur l’onglet « Amorce ». Le titre « Ce que je sais déjà » est affiché à l’écran.</p>
<p><strong>Narrateur</strong></p>
<p>La section « Contenu » contient des notions théoriques, des activités d’apprentissage, une stratégie pédagogique illustrée dans une capsule vidéo.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve à présent sur une page de l’onglet « Contenu ». Les contenus de la page changent pour illustrer les sections nommées par le narrateur, soit les notions théoriques, les activités d’apprentissage et les capsules vidéo.</p>
<p><strong>Narrateur</strong></p>
<p>Dans la section « Bilan », vous aurez l’occasion de revenir sur ce que vous avez appris dans ce module, visionner une synthèse animée de tout ce que vous venez d’apprendre, poursuivre votre réflexion à partir d’une citation, utiliser des activités pédagogiques à faire avec les élèves que nous avons adaptées lorsqu’il s’avère pertinent, faire une auto-évaluation de vos apprentissages dans le module, et finalement, voir des propositions de sites pertinents à consulter pour en savoir plus.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve à présent sur une page de l’onglet « Bilan ». Le contenu à l’écran change à mesure que le narrateur parle : une page avec le titre « Ce que j’ai appris », une page avec le titre « Synthèse des principes » et une liste de capsules vidéo, une page avec le titre « À bien y penser ! » figurant une citation, une page avec le titre « Activités pédagogiques » figurant des liens de téléchargement de documents PDF, une page avec le titre « Autoévaluation », et une page avec le titre « Pour en savoir + » avec des liens Web.</p>
<p><strong>Narrateur</strong></p>
<p>Dans la section « Boîte à outils », nous avons regroupé certains éléments pour une consultation plus facile : Activités pédagogiques, Bibliographie, Citations, Constat et mise au point, En bref, Figures, Tableaux.</p>
<p><strong>Description visuelle</strong></p>
<p>On revient à l’écran de la table des matières. Sur l’image de la toile de mots, une flèche désigne le terme « Boîte à outils ». L’écran change pour montrer les différents onglets se trouvant dans la section « Boîte à outils » que nomme le narrateur.</p>
<p><strong>Narrateur</strong></p>
<p>Dans le coin supérieur droit du site, vous pouvez en tout temps revenir à la page d’accueil ou à la table des matières, consulter les fonctions d’aide ou le glossaire, accéder à la boîte à outils. Par ailleurs, l’onglet activé en vert vous indique dans quelle section du site vous vous trouvez.</p>
<p><strong>Description visuelle</strong></p>
<p>Une flèche désigne diverses icônes dans le coin supérieur droit de la bannière au haut de l’écran du site Web : une maison pour la page d’accueil, les lettres « TDM » pour la table des matières, un point d’interrogation pour les fonctions d’aide, les lettres « ABC » pour le glossaire et les lettres « BAO » pour la boîte à outils. La flèche désigne ensuite l’onglet activé en vert sous la bannière. Il y a trois onglets, soit « Amorce », « Contenu » et « Bilan ». L’onglet activé en vert est « Contenu ».</p>
<p><strong>Narrateur</strong></p>
<p>Finalement, un menu contextuel déroulant est toujours accessible pour vous aider à naviguer dans les sous-menus, alors que la barre de navigation en bas de l’écran vous permet d’accéder à la section précédente ou suivante.</p>
<p><strong>Description visuelle</strong></p>
<p>La flèche désigne le menu contextuel qui se trouve directement sous les onglets, puis elle désigne la barre de navigation au bas de l’écran. Le mot « Fin » apparaît sur un écran blanc.</p>
`


    return (
      <>
        <Header />
        <main className="VideoPage">
          <Row>



            <Col xs="12" xl="9" className="mx-auto mb-4">
              <div className="inner-div pl-4">
              <h1>Vidéo tutoriel de l'autoformation <em>Approche culturelle de l’enseignement</em>, durée&nbsp;: 6&nbsp;min&nbsp;52&nbsp;s</h1>
              </div>
            </Col>



            <Col xs="12" xl="8" className="ml-auto mb-4">
              <AblePlayerComponent src='Ressources/Ateliers/Visite_guidee_Approche_Culturelle.mp4' captionSrc='Ressources/Ateliers/Approche-culturelle_narration.txt' videoDescription={vidDesc} poster="img/approcheCulturelVideo.png"/>
            </Col>



            <Col xs="12" xl="1" className="mr-auto mt-3">
              <div id="sticky-social-media-right" className="social-media-widget-right-side">
              <ul>
                      <li>
                          <a href={"https://www.facebook.com/sharer/sharer.php?u="+encodeURI(window.location.href)} target="_blank" class="facebook" title="Partager sur Facebook">
                              <span className="fab fa-facebook-f" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur Facebook. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"http://twitter.com/share?text=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;url="+encodeURI(window.location.href)} class="twitter" target="_blank" title="Partager sur Twitter">
                              <span className="fab fa-twitter" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur Twitter. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + encodeURI(window.location.href) + "&amp;title=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;summary=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;source=LinkedIn"} class="linkedin" target="_blank" title="Partager sur LinkedIn">
                              <span className="fab fa-linkedin-in" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur LinkedIn. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"mailto:?subject=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;body=Visitez%20la%20ressource%20J%27enseigne%20en%20fran%C3%A7ais%20au%20canada%20en%20acc%C3%A9dant%20au%20lien%20suivant%20%3A%20"+ encodeURI(window.location.href)} class="email" target="_blank" title="Partager par courriel">
                              <span className="fas fa-envelope" aria-hidden="true"></span>
                              <span className="sr-only">Partager par courriel. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      {/*<li>
                          <a href="http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fedusourceontario.com%2Fres%2Fmaths-apprendre-enseigner-innover&amp;media=%2F%2Fimages.ctfassets.net%2Fcfektv4t16rw%2F5kcLYdcNyYWrhWRVlm7Kfe%2F46dd6489ee132b4d0dd0929788f3bc21%2Fmath-app-ens-innov.png&amp;description=%C3%89l%C3%A9ments%20fondamentaux%20en%20math%C3%A9matiques" target="_blank" class="pinterest" title="Partager sur Pinterest">
                              <span class="fab fa-pinterest" aria-hidden="true"></span>
                              <span class="sr-only">Partager sur Pinterest. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>*/}
                  </ul>
                </div>
              </Col>




          </Row>
        </main>
        <Footer />
      </>
    );
  }
}
export default VideoVisiteGuideeApprocheCulturellePage;
