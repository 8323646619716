import React from "react";
import {Component} from 'react'
import {connect} from 'react-redux'

import {Row, Col} from 'react-bootstrap';

import Header from "../Components/Header";
import Footer from "../Components/Footer";





class SaviezVousPage extends Component {

  render() {

    return (
      <div>
        <Header />
        <main className="DidYouKnow">
          <Row>
            <Col xs="12" md="6" lg="5" className="px-5 px-md-3">
            <h1>Saviez-vous que...?</h1>
              <Row>
                {this.props.SaviezVous.map((SaviezVous) => {
                  return (
                    <>
                    <Col xs="2" key={SaviezVous.Id} className="DidYouKnowImgBox">
                      <img src={"img/"+SaviezVous.Image} alt=""></img>
                    </Col>
                    <Col xs="10" key={SaviezVous.Id} className="DidYouKnowParagraphBox">
                      <p>{SaviezVous.Texte}</p>
                    </Col>
                    </>
                  )
                })}
              </Row>
            </Col>
            <Col xs="12" md="6" lg="7" className="px-5 px-md-3">
              <h1>Témoignages</h1>
                <Row>
                  {this.props.Temoignage.map((Temoignage) => {
                    return (
                      <Col xs="12" key={Temoignage.Id} className="TemoignageBox">
                        <img src={"img/"+Temoignage.Image} alt=""></img>
                          <p>{Temoignage.Texte}</p>
                      </Col>
                    )
                  })}
                </Row>
            </Col>
          </Row>
        </main>
        <Footer />
      </div>
    );
  }


}



function mapStateToProps(state, ownProps){
    return{
      SaviezVous : state.SaviezVousReducer,
      Temoignage : state.TemoignageReducer
    };
}




export default connect(mapStateToProps)(SaviezVousPage);
