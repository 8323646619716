export const UPDATE_PROFESSIONNEL = 'Filter:updateProfessionnel'
export const UPDATE_BESOIN = 'Filter:updateBesoin'
export const UPDATE_PDF = 'Filter:updatePdf'
export const UPDATE_VIDEO = 'Filter:updateVideo'
export const UPDATE_HTMLIMAGE = 'Filter:updateHtmlImage'
export const UPDATE_HTMLINTERACTIF = 'Filter:updateHtmlInteractif'
export const UPDATE_HTMLAPPLICATION = 'Filter:updateHtmlApplication'

export function updateProfessionnel(id){
    return {
      type:UPDATE_PROFESSIONNEL,
      payload: {
        TypeDeProfessionel : id
      }
    }
}

export function updateBesoin(id){
    return {
      type:UPDATE_BESOIN,
      payload: {
        Besoin : id
      }
    }
}

export function updatePdf(bool){
    return {
      type:UPDATE_PDF,
      payload: {
        Pdf : bool
      }
    }
}
export function updateVideo(bool){
    return {
      type:UPDATE_VIDEO,
      payload: {
        Video : bool
      }
    }
}
export function updateHtmlImage(bool){
    return {
      type:UPDATE_HTMLIMAGE,
      payload: {
        HtmlTextImage : bool
      }
    }
}
export function updateHtmlInteractif(bool){
    return {
      type:UPDATE_HTMLINTERACTIF,
      payload: {
        HtmlInteractif : bool
      }
    }
}
export function updateHtmlApplication(bool){
    return {
      type:UPDATE_HTMLAPPLICATION,
      payload: {
        HtmlApplication : bool
      }
    }
}
