import React from "react";
import {Component} from 'react'

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AblePlayerComponent from "../Components/AblePlayerComponent"

class NousJoindrePage extends Component {
  render() {

    var vidDesc =`<p><strong>Aide à la navigation :</strong> La vidéo présente en alternance les propos d’un narrateur et les éléments visuels de la vidéo qui sont porteurs de contenu.</p>
  <p><strong>Narrateur</strong></p>
  <p>Comment utiliser <em>J’enseigne en français au Canada</em> de façon efficace? Le site <em>J’enseigne en français au Canada</em> répond à trois objectifs principaux. Premièrement, il met en valeur des ressources pédagogiques pancanadiennes du Comité du Consortium du français langue première. Deuxièmement, il facilite l’appropriation de ces ressources par les enseignantes et les enseignants qui enseignent en français, au Canada, dans un milieu minoritaire. Et troisièmement, il permet une recherche rapide dans les différentes ressources qui se trouvent dans les autoformations <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>.</p>
  <p>Comme vous découvrirez, le site <em>J’enseigne en français au Canada</em> est organisé selon ces trois intentions. Vous avez peut-être remarqué la vidéo d’introduction sur la page d’accueil. Elle vous donne un aperçu général du site. N’hésitez pas à la faire connaître à vos collègues!</p>
  <p><strong>Description visuelle</strong></p>
  <p>Le logo de <em>J’enseigne en français au Canada</em> apparaît à l’écran, puis une page du site Web s’affiche. Sur le côté gauche de la page se trouve une vidéo sous le titre «&nbsp;Découvrez les ressources&nbsp;». Sur le côté droit se trouvent des menus contextuels pour faire une recherche. Sous ces menus apparaissent deux autoformations, soit <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>. Sur un fond vert apparaissent les mots «&nbsp;Trois objectifs principaux&nbsp;», puis le logo du Conseil des ministres de l’Éducation (Canada) (CMEC) ainsi que le nom du Comité du consortium du français langue première. Les mots «&nbsp;s’approprier les ressources&nbsp;» apparaissent alors sous la barre d’outils du site Web, avec une flèche qui pointe vers un onglet nommé «&nbsp;Les ateliers&nbsp;». Puis, un zoom est effectué sur le menu de recherche de la page. Le curseur clique sur le premier menu contextuel où l’on demande «&nbsp;Quel type de professionnel êtes-vous?&nbsp;», puis sur le deuxième menu, où l’on demande «&nbsp;Quels sont vos besoins?&nbsp;». Le curseur clique sur «&nbsp;ALLEZ-Y!&nbsp;» pour lancer la recherche. Les résultats qui apparaissent se rapportent tous aux autoformations <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>. On effectue un zoom sur la vidéo à la gauche de la page, sous le titre «&nbsp;Découvrez les ressources&nbsp;».</p>
  <p><strong>Narrateur</strong></p>
  <p>Parcourons maintenant plus en détail les trois composantes principales du site et commençons par les deux autoformations, <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>. Il s’agit de deux sites distincts. Si vous visitez ces autoformations pour la première fois, vous remarquerez qu’elles exigent chacune la création d’un compte pour suivre l’autoformation. Nous vous invitons à planifier un moment pour le faire. C’est simple et rapide.</p>
  <p><strong>Description visuelle</strong></p>
  <p>Sur la page d’accueil du site, les deux autoformations sont mises en évidence. Le curseur clique sur «&nbsp;ALLEZ-Y&nbsp;» sous l’encadré de <em>Communico</em>. Une fenêtre s’affiche avec le nom de l’autoformation et la phrase suivante : «&nbsp;Le présent lien vous dirigera vers un autre site, extérieur au site <em>J’enseigne en français au Canada</em>&nbsp;». Le curseur clique sur un autre bouton «&nbsp;ALLEZ-Y&nbsp;», et l’on se retrouve sur la page du site <em>Communico</em>. L’écran est alors divisé en deux pour montrer les deux sites des autoformations. Dans le coin supérieur droit de chacune des pages se trouve un espace pour s’inscrire, c’est-à-dire se créer un compte, et pour se connecter une fois le compte créé.</p>
  <p><strong>Narrateur</strong></p>
  <p>Si nous restons sur le site <em>J’enseigne en français au Canada</em>, vous trouverez, dans la section «&nbsp;Les ateliers&nbsp;», un tutoriel pour chacune des deux autoformations. Ces tutoriels vous guideront pour créer un compte dans ces autoformations, mais aussi sur leur utilisation.</p>
  <p>Cela nous amène à la composante recherche du site <em>J’enseigne en français au Canada</em>. Vous n’avez peut-être pas présentement l’occasion de suivre une autoformation. La recherche vous permet de consulter plusieurs ressources qu’elles contiennent à partir d’un seul endroit. Par défaut, les ressources sont présentées en ordre alphabétique, que vous pouvez défiler pour obtenir un aperçu général. Des pastilles vous indiquent clairement si les ressources font partie de <em>Communico</em>, de l’<em>Approche culturelle de l’enseignement</em> ou d’autres sources.</p>
  <p>Pour une recherche plus précise, sélectionnez parmi les deux critères qui vous sont proposés le type de professionnel que vous êtes et la nature de vos besoins. Cliquez sur «&nbsp;ALLEZ-Y&nbsp;» et les résultats pertinents seront affichés. Vous pouvez également filtrer les ressources selon leur format, par exemple des PDF et des capsules vidéo.</p>
  <p><strong>Description visuelle</strong></p>
  <p>De retour sur le site <em>J’enseigne en français au Canada</em>, le curseur clique sur l’onglet «&nbsp;Les ateliers&nbsp;» au haut de l’écran. La page «&nbsp;Les ateliers&nbsp;» s’affiche. On y trouve, au bas, une vidéo tutoriel pour chacune des deux autoformations. Le curseur clique sur l’onglet «&nbsp;Recherche&nbsp;» au haut de la page, à côté de l’onglet «&nbsp;Les ateliers&nbsp;». La page de résultats de recherche s’affiche de nouveau. On fait défiler la page vers le bas pour montrer les diverses ressources qui y sont présentées, chacune affichant une pastille soit gris foncé pour <em>Communico</em> ou bleue pour <em>Approche culturelle de l’enseignement</em>. On revient au haut de la page. Pour le critère de recherche «&nbsp;Quel type de professionnel êtes-vous?&nbsp;», le type de professionnel qui est choisi est «&nbsp;Personnel enseignant&nbsp;», alors que pour le critère «&nbsp;Quels sont vos besoins?&nbsp;», l’option choisie est «&nbsp;L’approche culturelle de l’enseignement&nbsp;». Le curseur clique sur «&nbsp;ALLEZ-Y!&nbsp;» et les résultats pertinents s’affichent. Le curseur clique sur le bouton «&nbsp;Filtrer les résultats&nbsp;», qui se trouve au haut des résultats. Les options de filtrage qui apparaissent sont : PDF, Vidéo, HTML (texte/image), HTML (interactif) et HTML (application).</p>
  <p><strong>Narrateur</strong></p>
  <p>Portez attention à l’icône d’utilisateur dans le coin droit, en bas des vignettes de ressources. Elles vous signalent les ressources qui vous demanderont d’avoir un compte dans l’autoformation où se trouve la ressource. Toutes les autres ressources sont disponibles directement à partir de <em>J’enseigne en français au Canada</em> et vous pouvez y accéder sans compte ou sans quitter le site. Vous ne savez pas quoi chercher? Consultez la rubrique «&nbsp;Le saviez-vous?&nbsp;» en défilant la page d’accueil. Vous serez peut-être surpris de ce qu’il y a à découvrir!</p>
  <p><strong>Description visuelle</strong></p>
  <p>Une icône d’utilisateur se trouve dans le coin droit de la vignette d’une des ressources sur la page de résultats. Lorsque le curseur clique sur le bouton «&nbsp;Ouvrir&nbsp;» au bas de la ressource, une fenêtre apparaît à l’écran avec le nom de la ressource et une description, avec un bouton qui dit «&nbsp;Accéder&nbsp;». On revient à la page d’accueil, qu’on fait défiler vers le bas pour dévoiler la rubrique «&nbsp;Le saviez-vous?&nbsp;».</p>
  <p><strong>Narrateur</strong></p>
  <p>Regardons maintenant la section «&nbsp;Les ateliers&nbsp;». La section «&nbsp;Les ateliers&nbsp;» constitue la boîte à outils pour aider le personnel enseignant à s’approprier les ressources du site <em>J’enseigne en français au Canada</em>. Outre le présent tutoriel, la vidéo d’introduction et les deux tutoriels pour les autoformations <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>, cette section vous propose trois présentations. Le but de ces présentations est d’offrir du matériel au personnel enseignant qui désirerait présenter à d’autres enseignantes et enseignants, ou aux parents, les ressources du site <em>J’enseigne en français au Canada</em>, de <em>Communico</em> et de l’<em>Approche culturelle de l’enseignement</em>.</p>
  <p><strong>Description visuelle</strong></p>
  <p>On revient à la page «&nbsp;Les ateliers&nbsp;», où se trouvent la vidéo de présentation du site, les vidéos tutoriel des autoformations, ainsi que trois ateliers, ou présentations. On accède à l’une des présentations PowerPoint, puis différentes présentations sont montrées.</p>
  <p><strong>Narrateur</strong></p>
  <p>Trois présentations sont proposées. La première est utile pour les journées pédagogiques. La deuxième, plus courte, est idéale pour les ateliers ou les présentations lors de conférences ou pour les rencontres de direction. Et la troisième sert pour les rencontres de parents. </p>
  <p>Ces présentations sont offertes en deux différents formats. Vous pouvez les regarder directement en ligne à partir du site <em>J’enseigne en français au Canada</em>, mais il est aussi possible de les télécharger en version PowerPoint que vous pouvez sauvegarder et modifier à votre guise. Dans les deux cas, des consignes à l’animateur vous sont proposées. Vous pouvez imprimer une version PDF de ces consignes pour la présentation en ligne. La version en ligne vous propose de naviguer entre les onglets afin que vous puissiez choisir le site que vous voulez présenter. Des boutons, en bas, à droite, et des flèches «&nbsp;suivant&nbsp;» et «&nbsp;précédent&nbsp;» vous permettent de naviguer entre les pages.</p>
  <p><strong>Description visuelle</strong></p>
  <p>De retour sur la page «&nbsp;Les ateliers&nbsp;», la première présentation est mise en évidence. Elle porte le titre «&nbsp;Présentation longue, durée estimée : 4 h&nbsp;». La deuxième porte le titre «&nbsp;Présentation courte, durée estimée : 30 min.&nbsp;» et la troisième, le titre «&nbsp;Présentation pour les parents, durée estimée : 30 min.&nbsp;». Un zoom avant est effectué sur la troisième présentation. Dans le coin inférieur droit se trouvent le bouton «&nbsp;Aperçu&nbsp;», qui est le lien pour la regarder directement à partir du site <em>J’enseigne en français au Canada</em>, ainsi qu’une icône pour la télécharger en version PowerPoint. La présentation est lancée, et on saute à la section «&nbsp;Consignes à l’animateur&nbsp;». Dans le coin supérieur droit de la présentation se trouve une icône pour l’imprimer. Sur la version en ligne de la présentation, des onglets pour les différents sites se trouvent au haut de la page pour naviguer entre ceux-ci. En bas à droite se trouvent trois cercles, et un peu plus haut, une flèche pour naviguer entre les pages.</p>
  <p><strong>Narrateur</strong></p>
  <p>Et voilà, vous êtes maintenant en mesure de tirer profit de cette nouvelle ressource et des deux autoformations du Comité du Consortium du français langue première. Surtout, parlez-en! À nous, en nous faisant parvenir votre appréciation et en nous laissant un témoignage, et à vos pairs, en leur présentant la ressource. Bonne exploration!</p>
  <p><strong>Description visuelle</strong></p>
  <p>On revient à la page d’accueil du site. La phrase «&nbsp;Qu’en pensez-vous?&nbsp;» apparaît sous les onglets du haut, avec une flèche qui pointe vers l’onglet «&nbsp;Votre avis&nbsp;». Sur la page «&nbsp;Votre avis&nbsp;» se trouve un sondage. La vidéo se termine sur le logo de <em>J’enseigne en français au Canada</em>.</p>
  `

    return (
      <>
        <Header />
        <main className="NousJoindrePage mb-4">
          <h1>Nous joindre</h1>
          <div className="inner-div pl-4">

            <p>Le Conseil des ministres de l’Éducation (Canada) [CMEC] est un organisme intergouvernemental fondé en 1967 par les ministres responsables de l’Éducation au Canada. L’un de ses objectifs principaux est de servir de mécanisme grâce auquel les ministres peuvent entreprendre, en éducation, des activités, des projets et des initiatives dans des domaines d’intérêt commun.</p>

            <p>Le Comité du Consortium du français langue première (CCFLP) a été créé en 2000. Ce comité est composé des ministères de l’Éducation de tout le Canada, à l’exception du Québec et ses objectifs sont les suivants&nbsp;:</p>

            <ul>
              <li>comprendre les enjeux de l’éducation en langue française en milieu linguistique minoritaire;</li>
              <li>déterminer les besoins communs en ce qui a trait à l’enseignement et à l’apprentissage;</li>
              <li>élaborer des stratégies et des ressources pour répondre aux besoins.</li>
            </ul>

            <p>Le CCFLP présente <em>J’enseigne en français au Canada</em>, un environnement virtuel pour le personnel éducatif, lui permettant de découvrir et d’explorer toutes les composantes des ressources professionnelles du CCFLP et de les intégrer dans ses pratiques en un clic.</p>

            <p>Pour en savoir plus sur cette ressource, veuillez communiquer avec l’unité des Langues officielles du Secrétariat du CMEC (<a href="mailto:projetflp@cmec.ca" target="_top">projetflp@cmec.ca</a>).</p>

          </div>


          <h1 class="mt-3">Aide</h1>
          <div className="inner-div pl-4">
            <p class="mb-0">Pour de l'aide sur la façon d’utiliser efficacement le site <em>J’enseigne en français au Canada</em>, vous pouvez visionner le tutoriel suivant.</p>
          </div>
          <AblePlayerComponent src='https://player.vimeo.com/external/400269178.hd.mp4?s=fd732dfc37282eb871c8ed2537643f8e03fc7860&profile_id=174' captionSrc='Ressources/Ateliers/VideoTutoriel.txt' videoDescription={vidDesc} poster="img/aideVideo.png"/>




        </main>
        <Footer />
      </>
    );
  }
}
export default NousJoindrePage;
