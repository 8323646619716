const TemoignageData = [
  {
    "Id": 0,
  	"Image":"",
  	"Texte" : "Je suis une enseignante du Nouveau-Brunswick, et j'utilise la Banque de référents culturels pour créer des activités en classe."
  },
  {
    "Id": 1,
  	"Image":"",
  	"Texte" : "En tant que surintendant, je trouve que les outils de présentation qu'offre ce site me permettent de mieux outiller le personnel scolaire de mon conseil."
  },
  {
    "Id": 2,
  	"Image":"",
  	"Texte" : "Temoignage3"
  },
  {
    "Id": 3,
  	"Image":"",
  	"Texte" : "Temoignage4"
  }
]
export default TemoignageData;
