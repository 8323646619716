import React from "react";
import {Component} from 'react'
import {Row, Col} from 'react-bootstrap';

import Header from "../Components/Header";
import Footer from "../Components/Footer";

class AutresRessourcesPage extends Component {
  render() {
    return (
      <>
        <Header />
        <main className="AutresRessourcesPage">
          <h1>Autres ressources</h1>
          <div className="inner-div pl-4">
          <p>Voici d’autres ressources pertinentes. </p>
          <Row className="py-3">
            <Col xs="12" md="6" xl="3">
              <a href="https://www.acelf.ca/" target="_blank" title="ACELF - Association canadienne d’éducation de langue française"><img src="img/acelf.png" className="card-img" alt="Logo du site de l’ACELF - Association canadienne d’éducation de langue française"/></a>
            </Col>
            <Col xs="12" md="6" xl="9">
              <p>Le site de l’association canadienne d’éducation de langue française (ACELF) est une ressource centrale pour l’enseignement du français langue première en contexte minoritaire au Canada, particulièrement pour ce qui touche la construction identitaire. Vous y trouverez également plusieurs autres ressources. </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" xl="3">
              <a href="https://www.pelf.ca/" target="_blank" title="Pédagogie de l’école de langue française (PELF)"><img src="img/PELF.png" className="card-img" alt="Logo du site pédagogie de l’école de langue française (PELF)" /></a>
            </Col>
            <Col xs="12" md="6" xl="9">
              <p>La pédagogie de l’école de langue française (PELF) est destinée à la formation pédagogique en contexte minoritaire au Canada. Il s’agit d’une initiative des ministères de l’Éducation des provinces et territoires canadiens, réalisée avec l’aide de la Fédération canadienne des enseignantes et des enseignants (FCE). Vous y trouverez des documents sur la stratégie d’enseignement et aussi plusieurs ressources complémentaires. </p>
            </Col>
          </Row>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
export default AutresRessourcesPage;
