import {UPDATE_PROFESSIONNEL, UPDATE_BESOIN, UPDATE_PDF, UPDATE_VIDEO, UPDATE_HTMLIMAGE, UPDATE_HTMLINTERACTIF, UPDATE_HTMLAPPLICATION} from '../actions/FilterActions'

const FilterReducer =  (state = {}, {type, payload}) => {

      switch (type) {
        case UPDATE_PROFESSIONNEL:
            return Object.assign({}, state, {TypeDeProfessionel : payload.TypeDeProfessionel})
        case UPDATE_BESOIN:
            return Object.assign({}, state, {Besoin : payload.Besoin})
        case UPDATE_PDF:
            return Object.assign({}, state, {Pdf : payload.Pdf})
        case UPDATE_VIDEO:
            return Object.assign({}, state, {Video : payload.Video})
        case UPDATE_HTMLIMAGE:
            return Object.assign({}, state, {HtmlTextImage : payload.HtmlTextImage})
        case UPDATE_HTMLINTERACTIF:
            return Object.assign({}, state, {HtmlInteractif : payload.HtmlInteractif})
        case UPDATE_HTMLAPPLICATION:
            return Object.assign({}, state, {HtmlApplication : payload.HtmlApplication})
        default:
            return state
      }
}


export default FilterReducer;
