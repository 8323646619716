import React from "react";
import {Component} from 'react'
import {connect} from 'react-redux'


import {updatePdf, updateVideo, updateHtmlImage, updateHtmlInteractif, updateHtmlApplication} from '../actions/FilterActions'
import {Row, Col} from 'react-bootstrap';

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Filter from "../Components/FilterComponent"

import FicheRessourceComponent from "../Components/FicheRessourceComponent"

class ListeRessourcesPages extends Component {

  constructor(props) {
    super(props);
    this.state = {
         Pdf: this.props.Filter.Pdf,
         Video: this.props.Filter.Video,
         HtmlTextImage: this.props.Filter.HtmlTextImage,
         HtmlInteractif: this.props.Filter.HtmlInteractif,
         HtmlApplication: this.props.Filter.HtmlApplication
    }
    this.CreateRessourcesListe = this.CreateRessourcesListe.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }



  CreateRessourcesListe(){

    var FiltredRessource = this.props.AllResources




    if (this.props.Filter.TypeDeProfessionel !== 0){
      FiltredRessource = FiltredRessource.filter(x => x.TypeDeProfessionel.includes(this.props.Filter.TypeDeProfessionel) )
    }
    if (this.props.Filter.Besoin !== 0){
      FiltredRessource = FiltredRessource.filter(x => !x.Besoin.includes(this.props.Filter.Besoin))
    }

    if (this.props.Filter.Pdf === false){
      FiltredRessource = FiltredRessource.filter(x => !x.Format.includes(0))
    }
    if (this.props.Filter.Video === false){
      FiltredRessource = FiltredRessource.filter(x => !x.Format.includes(1))
    }
    if (this.props.Filter.HtmlTextImage === false){
      FiltredRessource = FiltredRessource.filter(x => !x.Format.includes(2))
    }
    if (this.props.Filter.HtmlInteractif === false){
      FiltredRessource = FiltredRessource.filter(x => !x.Format.includes(3))
    }
    if (this.props.Filter.HtmlApplication === false){
      FiltredRessource = FiltredRessource.filter(x => !x.Format.includes(4))
    }





    FiltredRessource = FiltredRessource.sort(function(a, b){
        if(a.Titre < b.Titre) { return -1; }
        if(a.Titre > b.Titre) { return 1; }
        return 0;
    })





    return FiltredRessource.map((Ressource) => {
      return (
        <FicheRessourceComponent key={Ressource.UniqueKey} data={Ressource}/>
      )
    });
  }


  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;

    this.setState({
      [id]: value
    });

    this.props.onUpdatePdf(document.getElementById('Pdf').checked)
    this.props.onUpdateVideo(document.getElementById('Video').checked)
    this.props.onUpdateHtmlImage(document.getElementById('HtmlTextImage').checked)
    this.props.onUpdateHtmlInteractif(document.getElementById('HtmlInteractif').checked)
    this.props.onUpdateHtmlApplication(document.getElementById('HtmlApplication').checked)

  }

  render() {
    return (
      <div>
        <Header />
        <main className="listeRessourcesPage">
          <Row>
            <Col xs="12" md="6" lg="5">
              <Row>
                <Col xs="12" lg="10"  className="px-5 px-md-3 py-3 inner-div">
                  <h2>Découvrez les ressources</h2>
                  <p>Vous êtes : «&nbsp;<strong>Personnel enseignant</strong>&nbsp;». Vous cherchez de l'information sur : «&nbsp;<strong>Les compétences langagières</strong>&nbsp;»? Voici des liens vers des ressources spécifiques qui sont disponibles dans les autoformations <em>Communico</em> et l'<em>Approche culturelle de l'enseignement</em>.</p>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="6" lg="7" className="px-5 px-md-3">
              <Filter history={this.props.history}/>
            </Col>
          </Row>


          <Row className="ressources">
            <Col xs="12">
            <button className="btn btn-primary mx-auto d-block" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              Filtrez les resultats
            </button>

            <div className="collapse" id="collapseExample">
              <div className="card card-body">
                <p><strong>Filtrer par Format : </strong></p>
                <div className="formatList">

                  <span><input type="checkbox" id="Pdf" checked={this.state.Pdf} onChange={this.handleChange}/><label for="Pdf">PDF</label></span>
                  <span><input type="checkbox" id="Video" checked={this.state.Video} onChange={this.handleChange}/><label for="Video">Vidéo</label></span>
                  <span><input type="checkbox" id="HtmlTextImage" checked={this.state.HtmlTextImage} onChange={this.handleChange}/><label for="HtmlTextImage">HTML (texte/image)</label></span>
                  <span><input type="checkbox" id="HtmlInteractif" checked={this.state.HtmlInteractif} onChange={this.handleChange}/><label for="HtmlInteractif">HTML (interactif)</label></span>
                  <span><input type="checkbox" id="HtmlApplication" checked={this.state.HtmlApplication} onChange={this.handleChange}/><label for="HtmlApplication">HTML (application)</label></span>


                </div>
              </div>
            </div>
            </Col>
          {this.CreateRessourcesListe()}
          </Row>
        </main>
        <Footer />
      </div>
    );
  }
}


function mapStateToProps(state, ownProps){
    return{
      AllResources : state.RessourceReducer,
      Format : state.FormatReducer,
      Filter : state.FilterReducer
    };
}

const mapActionsToProps = {
    onUpdatePdf : updatePdf,
    onUpdateVideo : updateVideo,
    onUpdateHtmlImage : updateHtmlImage,
    onUpdateHtmlInteractif : updateHtmlInteractif,
    onUpdateHtmlApplication : updateHtmlApplication
}

export default connect(mapStateToProps, mapActionsToProps)(ListeRessourcesPages);
