import {createStore} from 'redux';
import rootReducers from "./Reducers"

import {RessourceData, FormatData, TypeDeProfessionelData, BesoinData} from './Data/RessourceData';
import SaviezVousData from './Data/SaviezVousData';
import TemoignageData from './Data/TemoignageData';

const initialState = {
  SaviezVousReducer : SaviezVousData,
  TemoignageReducer : TemoignageData,
  RessourceReducer : RessourceData,
  TypeDeProfessionelReducer : TypeDeProfessionelData,
  FormatReducer : FormatData,
  BesoinReducer : BesoinData,
  FilterReducer : {
    TypeDeProfessionel: 0,
    Besoin: 0,
    Pdf:true,
    Video:true,
    HtmlTextImage:true,
    HtmlInteractif:true,
    HtmlApplication:true
  },
};


//const store = createStore(rootReducers, initialState, applyMiddleware(...middleware));
const store = createStore(rootReducers, initialState, window.devToolsExtension && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
