import React from "react";
import {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";

import {Col, OverlayTrigger} from 'react-bootstrap';

import ModalComponent from "./ModalComponent";


class FicheRessourceComponent extends Component {

  CreateFormatList(){
    var list = ""
    this.props.data.Format.forEach((item, i) => {
      list += this.props.Format[item].Name + ", ";
    });
    return list.slice(0, -2)
  }
  render() {
    const renderTooltip = props => (
      <div className="OverlayTrigger"
        {...props}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          padding: '2px 10px',
          color: 'white',
          borderRadius: 3,
          ...props.style,
        }}
      >
      Vous devrez vous inscrire pour <br/>accéder à la ressource
      </div>
    );



    const description = (
      <>
      <p>Plusieurs ressources font partie d’autoformations qui exigent un compte utilisateur.</p>
      <p>S’inscrire aux autoformations permet de sauvegarder le progrès d'une session à l’autre.</p>
      <p>Si vous avez déjà un compte utilisateur vous n’aurez qu'à vous authentifier.</p>
      <p>Si nous n’avez pas de compte utilisateur, vous n’aurez qu'à vous «&nbsp;inscrire&nbsp;». C’est gratuit.</p>
      </>
    );

    const footer = (
      <a className="btn btn-primary" href={this.props.data.Url} target="_blank" title={"Accéder à la ressource : "+ this.props.data.Titre} rel="noopener noreferrer">Accéder</a>
    )



    return (


      <Col xs="12" md="6" lg="4" xl="3" className="FicheRessourceComponent">

        <div className="inner-div">
          <img className="Tumbnails img-fluid" src={"img/" + this.props.data.UniqueKey+".jpg"} alt="" />
          <img className="FicheIco CommunicO img-fluid" src={"img/"+this.props.data.Provenance+".png"} alt="" />



          {this.props.data.LoginNeeded ?
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltip}
            >
                <span className="fas fa-user-circle" aria-hidden="true"  variant="success"></span>
            </OverlayTrigger>
            : null
          }

          <p className="greenFont ressTitle"><strong>{this.props.data.Titre}</strong></p>

          <p>
            <strong>Domaine : </strong><br/>
            <strong>Type : </strong>{this.props.data.TypeDeRessource}<br/>
            <strong>Format : </strong>{this.CreateFormatList()}<br/>

            <strong>Date : </strong>{this.props.data.Date}<br/>
            <strong>Auteur : </strong>{this.props.data.Auteur}
          </p>

          <div className="buttonWrapper">
            <ModalComponent content={this.props.data.Description} vari="secondary" title="Description" button="Description"/>

            {this.props.data.LoginNeeded ?
              <ModalComponent content={description} title={this.props.data.Titre} button="Ouvrir"  footer={footer} />:
              <Link className="btn btn-primary" to={'/Ressource/'+this.props.data.UniqueKey} title={"Accéder à la ressource : "+ this.props.data.Titre}>Ouvrir</Link>
            }
            </div>
          </div>
      </Col>
    );
  }
}


function mapStateToProps(state, ownProps){
    return{
      Format : state.FormatReducer,
    };
}


export default connect(mapStateToProps)(FicheRessourceComponent);


/*
    UniqueKey : "C01",
    Provenance : "CommunicO",
    Titre : "Fondements et éléments d'un continuum langagier",
    Url : "https://communico.club/pluginfile.php/2/course/section/3/elem-contin-langagier.pdf",
    TypeDeRessource : "Guide pédagogique",
    Format : "PDF",
  Besoin : [1],
  TypeDeProfessionel : [1, 2, 5, 4, 3],
    Description : "Document théorique qui présente les fondements qui soutendent le développement du langage de l'enfant en fonction des groupes d'âge (préscolaire jusqu'au secondaire)",
    Date: "2008",
    Auteur: "Conseil des ministres de l'Éducation, Canada",
  Tumbnails : "",
  LoginNeeded : false
*/
