import React, {Component} from "react";
import {Row, Col} from 'react-bootstrap';
import $ from "jquery"
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

class AtelierLongPage extends Component {

  componentDidMount(){
		var $carousel= $(".carousel")
		$carousel.children('.carousel-control-prev').hide();
		$carousel.children(".prevCarousel").show();
		$carousel.children('.carousel-control-next').show();
		$carousel.children(".nextCarousel").hide();
	}

  render() {

    function handleClick(e){
      var $this = $(e.target).parents(".carousel");
      setTimeout(function(){
        if ($this.find('.carousel-item:first-child').hasClass('active')) {
            $this.children('.carousel-control-prev').hide();
            $this.children(".prevCarousel").show();
            $this.children('.carousel-control-next').show();
            $this.children(".nextCarousel").hide();
        } else if ($this.find('.carousel-item:last-child').hasClass('active')) {
            $this.children('.carousel-control-prev').show();
            $this.children(".prevCarousel").hide();
            $this.children('.carousel-control-next').hide();
            $this.children(".nextCarousel").show();
        } else {
            $this.children('.carousel-control-prev').show();
            $this.children(".prevCarousel").hide();
            $this.children('.carousel-control-next').show();
            $this.children(".nextCarousel").hide();
        }
      }, 620, $this);
    }


    return (
      <>
        <Header />
        <main className="atelier">

           <h1>Présentation longue</h1>

          <div className="accordion" id="accordionExample">
            <button id="headingOne" className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <h2>Introduction</h2>
            </button>
            <button id="headingTwo" className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <h2>Approche culturelle de l’enseignement</h2>
            </button>
            <button id="headingThree" className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <h2>Communico</h2>
            </button>
            <button id="headingFour" className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <h2>Appréciation</h2>
            </button>




            {/* ********************************************* */
            /* **************** Plan du site *************** */
            /* ********************************************* */}

              <div id="collapseOne" className="card collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div className="card-body">


                  <div id="carouselIntorduction" className="carousel slide" data-ride="carousel" data-interval="false"  data-wrap="false">
                      <ol className="carousel-indicators" onClick={handleClick}>
                        <li data-target="#carouselIntorduction" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselIntorduction" data-slide-to="1"></li>
                        <li data-target="#carouselIntorduction" data-slide-to="2"></li>
                        <li data-target="#carouselIntorduction" data-slide-to="3"></li>
                        <li data-target="#carouselIntorduction" data-slide-to="4"></li>
                      </ol>
                      <div className="carousel-inner">
            					  {/* **************** Slide 1.1 *************** */}
                        <div className="carousel-item active">
                          <Row>
                            <Col xs="12" xl="6" className="img firstSlide">
                              <img
                                className="d-block w-100"
                                src="img/atelier-long-4h-grande.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text firstSlide">
                              <h3>Atelier</h3>
                              <p>Journée pédagogique</p>

                              <div className="carreGris">
                                <p>L’atelier comprend quatre parties :</p>
                                  <ul>
                                    <li>Le site <em>J’enseigne en français au Canada</em></li>
                                      <li>Les ressources et l’autoformation du site <em>Approche culturelle de l’enseignement</em></li>
                                      <li>Les ressources et l’autoformation du site <em>Communico</em></li>
                                    <li>Appréciation de l’atelier</li>
                                  </ul>

                              </div>

                            </Col>
                          </Row>
                        </div>
            					  {/* **************** Slide 1.2 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Votre nouvelle porte d’entrée!</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/1.2.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p><em>J’enseigne en français au Canada</em> est la nouvelle porte d’entrée pour découvrir les ressources en français langue première en milieu minoritaire au Canada.</p>
                                <p><em>Cette ressource est produite avec le soutien financier des provinces et des territoires membres du CCFLP et du gouvernement du Canada.</em></p>
                              </div>

                            </Col>
                          </Row>
                        </div>
            					  {/* **************** Slide 1.3 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>L’outil de recherche</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/1.3.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li>Les domaines d’enseignement</li>
                                  <li>Les types de ressources</li>
                                  <li>Les formats</li>
                                </ul>
                              </div>

                            </Col>
                          </Row>
                        </div>
            					  {/* **************** Slide 1.4 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Faites une recherche...</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/1.4.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>Que cherchez-vous?</p>
                                <ul>
                                  <li>Je suis une enseignante ou un enseignant.</li>
                                  <li>Je cherche des référents culturels.</li>
                                  <li>Je cherche le continuum langagier du préscolaire.</li>
                                </ul>
                              </div>

                            </Col>
                          </Row>
                        </div>
            					  {/* **************** Slide 1.5 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Pour accéder aux sites...</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/1.5.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li><em>Communico</em></li>
                                  <li><em>Approche culturelle de l’enseignement</em></li>
                                </ul>
                                <p>Le site <em>J’enseigne en français au Canada</em> interagit et mène aux sites <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>.</p>
                              </div>

                            </Col>
                          </Row>
                        </div>




                      </div>

                      <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-presentation-longue.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                      <a className="carousel-control-prev" href="#carouselIntorduction" role="button" data-slide="prev" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                        <span className="sr-only">Précédent</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselIntorduction" role="button" data-slide="next" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Suivant</span>
                      </a>
                      <button className="carousel-control-next nextCarousel" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Approche culturelle de l’enseignement</span>
                      </button>
                  </div>

                </div>
              </div>



            {/* ********************************************* */
            /* *** Approche culturelle de l’enseignement *** */
            /* ********************************************* */}

              <div id="collapseTwo" className="card collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div className="card-body">


                  <div id="carouselCulturelle" className="carousel slide" data-ride="carousel" data-interval="false"  data-wrap="false">
                      <ol className="carousel-indicators" onClick={handleClick}>
                        <li data-target="#carouselCulturelle" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="1"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="2"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="3"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="4"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="5"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="6"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="7"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="8"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="9"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="10"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="11"></li>
                        <li data-target="#carouselCulturelle" data-slide-to="12"></li>
                      </ol>
                      <div className="carousel-inner">
                        {/* **************** Slide 2.1 *************** */}
                        <div className="carousel-item active">
                          <Row>
                            <Col xs="12" xl="6" className="img firstSlide">
                              <a className="imgLink" href="http://approcheculturelle.ca/index.php/auth/login" aria-label="Site externe Approche culturelle de l’enseignement - Page d'accueil" target="_blank" rel="noopener noreferrer">
                                <img
                                  className="d-block w-100"
                                  src="img/2.1.jpg"
                                  alt=""
                                />
                              </a>
                            </Col>
                            <Col xs="12" xl="6" className="text firstSlide">
                              <h3>Approche culturelle de l’enseignement</h3>
                              <p><a href="http://approcheculturelle.ca" target="_blank" rel="noopener noreferrer" title="approche culturelle (nouvel onglet)">approcheculturelle.ca</a></p>

                              <div className="carreGris">
                                <p>À la découverte du cadre pancanadien pour l’appropriation de la culture dans les écoles de langue française.</p>
                              </div>

                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.2 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>À qui s’adresse ce site?</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/2.2.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>L’approche culturelle de l’enseignement concerne le personnel enseignant, les cadres scolaires et bien plus. Il y a quelque chose pour vous.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.3 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Le <em>Cadre pancanadien pour l’appropriation de la culture dans les écoles de langue française</em></h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <a className="imgLink" href="http://approcheculturelle.ca/index.php/auth/login" aria-label="Site externe Approche culturelle de l’enseignement - Page d'accueil" target="_blank" rel="noopener noreferrer">
                                <img
                                  className="d-block w-100"
                                  src="img/2.3.jpg"
                                  alt=""
                                />
                              </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li>Un document de fondements pour une approche culturelle de l’enseignement;</li>
                                  <li>La Banque pancanadienne de référents culturels signifiants pour la francophonie;</li>
                                  <li>La Banque de stratégies pédagogiques intégrant l’approche culturelle de l’enseignement;</li>
                                  <li>Le site Web de formation sur l’approche culturelle de l’enseignement.</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.4 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Une visite guidée en sept minutes</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <a className="imgLink" href="VideoApprocheCulturelle" aria-label="Visite guidée Approche culturelle de l’enseignement" target="_blank" rel="noopener noreferrer">
                                <img
                                  className="d-block w-100"
                                  src="img/2.4.jpg"
                                  alt=""
                                />
                              </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p><strong>Découvrez le site Web <em>Approche culturelle de l’enseignement</em> en quelques minutes!</strong></p>
                                <p>Cette courte vidéo explicative vous permet rapidement d’identifier son contenu et de comprendre comment l’utiliser.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.5 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Les dix principes d’une approche culturelle de l’enseignement</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/A32" aria-label="Synthèse des principes de l'approche culturelle de l'enseignement" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.5.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li>Construction identitaire</li>
                                  <li>Programmes d’études</li>
                                  <li>Appropriation de la culture</li>
                                  <li>Langue et culture</li>
                                  <li>Dialogue authentique</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.6 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Les dix principes d’une approche culturelle de l’enseignement - suite</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/A32" aria-label="Synthèse des principes de l'approche culturelle de l'enseignement" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.6.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li>Héritage culturel</li>
                                  <li>Leadership culturel</li>
                                  <li>Engagement culturel</li>
                                  <li>Diversité culturelle</li>
                                  <li>Famille, école et communauté</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.7 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Intégration des référents culturels dans l’enseignement – Étape 1</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/2.7.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>Est-ce un référent culturel?</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.8 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Intégration des référents culturels dans l’enseignement – Étape 2</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="http://approcheculturelle.ca/assets/pdf/Dec7-12-AnnexeII-Documentsynthesefrancais.pdf" aria-label="Site externe Approche culturelle de l’enseignement - Section DOCUMENT SYNTHÈSE" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.8.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>Quelle est sa valeur pédagogique?</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.9 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Intégration des référents culturels dans l’enseignement – Étape 3</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="http://approcheculturelle.ca/assets/pdf/Dec7-12-AnnexeII-Documentsynthesefrancais.pdf" aria-label="Site externe Approche culturelle de l’enseignement - Section DOCUMENT SYNTHÈSE" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.9.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>Quelle est sa contribution à la formation globale de l'élève?</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.10 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>La Banque pancanadienne des référents culturels</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="https://referentsculturels.com" aria-label="Site externe Banque pancanadienne de référents culturels signifiants de la francophonie" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.10.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>La banque des référents culturels appuie la mise en œuvre d’une approche culturelle de l’enseignement dans les écoles de langue française du Canada.</p>
                                <p><strong>Explorons la banque!</strong></p>

                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.11 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>Dynamisme du processus d’appropriation de la culture francophone</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="http://approcheculturelle.ca/assets/pdf/Dec7-12-AnnexeII-Documentsynthesefrancais.pdf" aria-label="Site externe Approche culturelle de l’enseignement - Section DOCUMENT SYNTHÈSE" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.11.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p>L’individu au cœur du&nbsp;:</p>
                                <ul>
                                  <li>Savoir</li>
                                  <li>Savoir-être</li>
                                  <li>Savoir-faire</li>
                                </ul>

                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.12 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>La Banque de stratégies pédagogiques</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="/Ressource/A31" aria-label="Banque de stratégies pédagogiques sur l'approche culturelle de l'enseignement" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.12.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li>18 capsules vidéo montrent comment intégrer une approche culturelle de l’enseignement aux pratiques pédagogiques  en classe et dans le cadre de l'animation culturelle.</li> 
                                  <li>Le tournage de ces capsules vidéo s’est déroulé dans des écoles de langue française en contexte minoritaire au Canada.</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 2.13 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3>La Boîte à outils</h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <a className="imgLink" href="http://approcheculturelle.ca/index.php/affiche/contenu/cmec-bao-1" aria-label="Site externe Approche culturelle de l’enseignement - Section Boîte à outils" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/2.13.jpg"
                                alt=""
                              />
                            </a>
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <ul>
                                  <li>Activités pédagogiques (enseignants et élèves)</li>
                                  <li>Une bibliographie</li>
                                  <li>Des citations</li>
                                  <li>Un constat/mise au point</li>
                                  <li>En bref</li>
                                  <li>Des figures/tableaux</li>
                                  <li>Des pistes de visionnement</li>
                                  <li>Pour en savoir plus</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>



                      </div>

                      <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-presentation-longue.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                      <a className="carousel-control-prev" href="#carouselCulturelle" role="button" data-slide="prev" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                        <span className="sr-only">Précédent</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselCulturelle" role="button" data-slide="next" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Suivant</span>
                      </a>
                      <button className="carousel-control-prev prevCarousel" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                        <span className="sr-only">Introduction</span>
                      </button>
                      <button className="carousel-control-next nextCarousel" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Communico</span>
                      </button>
                    </div>




                </div>
              </div>



            {/* ********************************************* */
            /* ***************** Communico ***************** */
            /* ********************************************* */}

              <div id="collapseThree" className="card collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div className="card-body">

                  <div id="carouselCommunico" className="carousel slide" data-ride="carousel" data-interval="false"  data-wrap="false">
                      <ol className="carousel-indicators" onClick={handleClick}>
                        <li data-target="#carouselCommunico" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselCommunico" data-slide-to="1"></li>
                        <li data-target="#carouselCommunico" data-slide-to="2"></li>
                        <li data-target="#carouselCommunico" data-slide-to="3"></li>
                        <li data-target="#carouselCommunico" data-slide-to="4"></li>
                        <li data-target="#carouselCommunico" data-slide-to="5"></li>
                        <li data-target="#carouselCommunico" data-slide-to="6"></li>
                        <li data-target="#carouselCommunico" data-slide-to="7"></li>
                        <li data-target="#carouselCommunico" data-slide-to="8"></li>
                        <li data-target="#carouselCommunico" data-slide-to="9"></li>
                        <li data-target="#carouselCommunico" data-slide-to="10"></li>
                        <li data-target="#carouselCommunico" data-slide-to="11"></li>
                        <li data-target="#carouselCommunico" data-slide-to="12"></li>
                        <li data-target="#carouselCommunico" data-slide-to="13"></li>
                        <li data-target="#carouselCommunico" data-slide-to="14"></li>
                        <li data-target="#carouselCommunico" data-slide-to="15"></li>
                        <li data-target="#carouselCommunico" data-slide-to="16"></li>
                      </ol>
                      <div className="carousel-inner">
                        {/* **************** Slide 3.1 *************** */}
                        <div className="carousel-item active">
                          <Row>
                            <Col xs="12" xl="6" className="img firstSlide">
                              {/*<a className="imgLink" href="" aria-label="Site externe Communico - Section " target="_blank" rel="noopener noreferrer">*/}
                                <img
                                  className="d-block w-100"
                                  src="img/3.1.jpg"
                                  alt=""
                                />
                              {/*</a>*/}
                            </Col>
                            <Col xs="12" xl="6" className="text firstSlide">
                              <h3>Communico</h3>
                              <p>Trousse de formation en communication orale
                                <br/><a href="https://Communico.club/" target="_blank" rel="noopener noreferrer" title="Communico.club (nouvel onglet)">Communico.club</a></p>

                              <div className="carreGris">
                                <p>Autoformation et site de référence de la maternelle à la 12<sup>e</sup> année en matière de développement professionnel sur la communication orale dans les écoles de langue française en contextes minoritaires.</p>
                              </div>

                            </Col>
                          </Row>
                        </div>


                      {/* **************** Slide 3.2 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Une visite guidée en quatre minutes</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="VideoCommunico" aria-label="VISITE GUIDÉE Communico" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.2.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p><strong>Découvrir <em>Communico</em> en quelques minutes!</strong></p>
                              <p>Cette courte vidéo explicative vous permet rapidement d’identifier son contenu et de comprendre comment l’utiliser.</p>
                            </div>
                          </Col>
                        </Row>
                      </div>



                      {/* **************** Slide 3.3 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L'autoformation<br/>Module 1&nbsp;: Les élèves</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C19" aria-label="L'élève qui fréquente une école francophone en contexte minoritaireS" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.3.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <ul>
                                <li>Le continuum langagier</li>
                                <li>Le portrait psychopédagogique des élèves</li>
                                <li>Favoriser la communication orale</li>
                                <li>L’outil de découverte du milieu de vie de l’élève</li>
                                <li>Exploiter les ressources culturelles et médiatiques</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.4 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Capsule vidéo du module Élève</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C19" aria-label="L'élève qui fréquente une école francophone en contexte minoritaire" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.4.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>Êtes-vous sensible au bagage linguistique, culturel et identitaire de l’élève?</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.5 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L’élève : le développement du continuum langagier</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/3.5.jpg"
                                alt=""
                              />
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>Quatre volets et selon les groupes d’âge&nbsp;:</p>
                              <ul>
                                <li>La compétence linguistique</li>
                                <li>La compétence discursive</li>
                                <li>La compétence communicative</li>
                                <li>Les domaines d'intérêts</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.6 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L’élève : le développement du continuum langagier (suite)</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C02" aria-label="Le continuum langagier" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.6.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p><strong>Explorons!</strong></p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.7 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L'autoformation<br/>Module 2&nbsp;: Le personnel enseignant</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C20" aria-label="L'enseignement de l'oral" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.7.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <ul>
                                <li>Les croyances pédagogiques et la pratique réflexive</li>
                                <li>Le rôle du personnel enseignant</li>
                                <li>Favoriser la communication orale</li>
                                <li>Enseigner l’oral</li>
                                <li>Un climat favorisant l’apprentissage</li>
                                <li>Les modèles d’intervention</li>
                                <li>L’observation des compétences langagières</li>
                                <li>Les interventions langagières</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.8 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Capsule vidéo module : Personnel enseignant</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C20" aria-label="L'enseignement de l'oral" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.8.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>Une responsabilité partagée par l’ensemble du personnel enseignant!</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.9 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Sonder ses croyances</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            {/*<a className="imgLink" href="" aria-label="Site externe Communico - Section " target="_blank" rel="noopener noreferrer">*/}
                              <img
                                className="d-block w-100"
                                src="img/3.9.jpg"
                                alt=""
                              />
                            {/*</a>*/}
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>En tant qu’enseignant, quelles sont vos croyances, perceptions et pratiques envers les <strong>élèves</strong>, les <strong>parents</strong>, la <strong>communauté</strong> et l’<strong>école</strong>?</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.10 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L'autoformation<br/>Module 3&nbsp;: La classe</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C21" aria-label="Site externe Communico - Section ACTIVITÉ D’APPRENTISSAGE" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.10.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <ul>
                                <li>Favoriser la communication orale</li>
                                <li>Le scénario pédagogique</li>
                                <li>Des comptines, des chansons et des poèmes</li>
                                <li>Des expériences langagières</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.11 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Capsule vidéo du module&nbsp;: La classe</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C21" aria-label="La classe, milieu d'apprentissage de la langue française" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.11.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>La classe, un lieu privilégié!</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.12 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L'autoformation<br/>Module 4&nbsp;: L'école</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C22" aria-label="La classe, milieu d'apprentissage de la langue française" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.12.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <ul>
                                <li>La vitalité francophone à l’école</li>
                                <li>Favoriser la communication orale à l’école</li>
                                <li>Le mandat de l’école de langue française en contextes minoritaires</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.13 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Capsule vidéo du module L’école</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C22" aria-label="L'école de langue française en contexte minoritaire" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.13.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>Une collaboration avec la communauté et la famille afin d’exercer son rôle langagier, culturel et identitaire.</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.14 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>L'autoformation<br/>Module 5 : École-Famille-Communauté</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C22" aria-label="L'école de langue française en contexte minoritaire" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.14.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <ul>
                                <li>Favoriser la communication orale</li>
                                <li>L’inventaire des ressources</li>
                                <li>L’outil de découverte de la vitalité francophone de la communauté</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.15 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Capsule vidéo du module École-Famille-Communauté</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/C27" aria-label="École-famille-communauté" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.15.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>L’école de langue française joue un rôle de catalyseur pour le développement des communautés francophones.</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.16 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>Le journal d’une enseignante</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="https://jenseigne-en-franais.ca/Ressource/C17" aria-label="au fil des saisons" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.16.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p>Le cheminement d’une nouvelle enseignante en contexte minoritaire francophone, tout au long d’une année scolaire.</p>
                            </div>
                          </Col>
                        </Row>
                      </div>


                      {/* **************** Slide 3.17 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                            <h3>La Boîte à outils</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="https://communico.club/mod/standalone/content/22/index.php/contenu/lire/page/boite-a-outils.html" aria-label="Site externe Communico - Section BOÎTE À OUTILS" target="_blank" rel="noopener noreferrer">
                              <img
                                className="d-block w-100"
                                src="img/3.17.jpg"
                                alt=""
                              />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                            <div className="carreGris">
                              <p><strong>Pour vous appuyer!</strong></p>
                              <p>Des aide-mémoire, une feuille de route, des listes de ressources, des supports visuels, une médiagraphie… et bien plus!</p>
                            </div>
                          </Col>
                        </Row>
                      </div>



                    </div>

                    <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-presentation-longue.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                    <a className="carousel-control-prev" href="#carouselCommunico" role="button" data-slide="prev" onClick={handleClick}>
                      <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                      <span className="sr-only">Précédent</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselCommunico" role="button" data-slide="next" onClick={handleClick}>
                      <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                      <span className="sr-only">Suivant</span>
                    </a>
                    <button className="carousel-control-prev prevCarousel" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                      <span className="sr-only">Approche culturelle de l’enseignement</span>
                    </button>
                    <button className="carousel-control-next nextCarousel" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                      <span className="sr-only">Appréciation</span>
                    </button>
                  </div>



                </div>
              </div>



            {/* ********************************************* */
            /* **************** Appréciation *************** */
            /* ********************************************* */}

              <div id="collapseFour" className="card collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                <div className="card-body">

                  <div id="carouselAppreciation" className="carousel slide" data-ride="carousel" data-interval="false"  data-wrap="false">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <Row>
                            <Col xs="12" xl="6" className="img firstSlide">
                              <img
                                className="d-block w-100"
                                src="img/4.1.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text firstSlide">
                              <h3>Appréciation</h3>

                              <div className="carreGris">
                                  <ul>
                                    <li>Rétroaction</li>
                                    <li>Appréciation</li>
                                    <li>Témoignage</li>
                                  </ul>
                              </div>

                            </Col>
                          </Row>
                        </div>

                    </div>


                    <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-presentation-longue.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>


                    <button className="carousel-control-prev prevCarousel" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                      <span className="sr-only">Communico</span>
                    </button>
                  </div>


                </div>
              </div>





                            </div>




        </main>
        <Footer />
      </>
    );
  }
}
export default AtelierLongPage;




/*
<div className="carousel-item active">
  <Row>
    <Col className="titre">
      <h3>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</h3>
    </Col>
  </Row>
  <Row>
    <Col xs="12" xl="6" className="img">
      <img
        className="d-block w-100"
        src="https://via.placeholder.com/850x500"
        alt=""
      />
    </Col>
    <Col xs="12" xl="6" className="text">
      <div className="carreGris">
        xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
      </div>
    </Col>
  </Row>
</div>
*/
