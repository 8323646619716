import React from "react";
import {Component} from 'react'

import {Button, Modal} from 'react-bootstrap';

class ModalComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
           showModal: false
      }
      this.getInitialState = this.getInitialState.bind(this);
      this.close = this.close.bind(this);
      this.open = this.open.bind(this);
    }



    getInitialState() {
      return { showModal: false };
    }

    close() {
      this.setState({ showModal: false });
    }

    open() {
      this.setState({ showModal: true });
    }

    render() {

      return (
        <>
          <Button className={this.props.btnClass} variant={this.props.vari} onClick={this.open}>
            {this.props.button}
          </Button>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            show={this.state.showModal}
            onHide={this.close}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              {this.props.content}


            </Modal.Body>
            <Modal.Footer>
              {this.props.footer != null ?
                this.props.footer:
                <Button onClick={this.close}>Fermer</Button>
              }
            </Modal.Footer>
          </Modal>
        </>
      );
    }



}


export default ModalComponent;
