import React from "react";
import {Component} from 'react'
import {Row, Col} from 'react-bootstrap';

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import AblePlayerComponent from "../../Components/AblePlayerComponent"

class VideoVisiteGuideeCommunicoPage extends Component {
  render() {

  var vidDesc =`
<p><strong>Aide à la navigation :</strong> La vidéo présente en alternance les propos d’un narrateur et les éléments visuels de la vidéo qui sont porteurs de contenu. Cette vidéo sert à présenter les différentes facettes du site Web de Communico en projetant l’écran d’un ordinateur et en naviguant sur le site Web pour illustrer les propos du narrateur.</p>
<p><strong>Narrateur</strong></p>
<p>Cette brève visite guidée vous donne un aperçu du site de formation Web sur l’enseignement de la communication orale développé pour le personnel enseignant et les intervenants scolaires des écoles de langue française en contextes minoritaires.</p>
<p><strong>Description visuelle</strong></p>
<p>Le nom « Communico » apparaît sur un fond bleu au-dessus d’une image représentant un phare. De chaque côté du phare apparaissent les mots « visite guidée » et « écoles de langue française en contextes minoritaires ».</p>
<p><strong>Narrateur</strong></p>
<p>La page d’accueil présente le Conseil des ministres de l’Éducation du Canada et les coordonnées du projet. Pour avoir un aperçu de la formation, consultez les buts de la formation et la table des matières. Cette page d’accueil donne accès aux textes fondamentaux qui ont inspiré le contenu de cette formation. La synthèse réunit les capsules vidéo qui vous donnent une vue d’ensemble du contenu des cinq thèmes de la formation.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur la page d’accueil du site Web de Communico. Le titre « Visite guidée » est écrit au haut de l’écran. À l’aide du curseur, le narrateur montre deux composantes sur la page d’accueil : « LE CMEC : Présentation; Coordonnées du projet » et « Aperçu de la formation : Buts de la formation; Table des matières ». Des flèches apparaissent plus bas pour désigner d’autres composantes sur la page d’accueil : « Éléments d’un continuum langagier », « Modèles d’intervention » et « État des lieux en communication orale ». Le curseur vient se poser sur un autre mot, « Synthèse ».</p>
<p><strong>Narrateur</strong></p>
<p>Pour accéder au menu principal de la formation, vous devez d’abord vous inscrire et créer votre compte d’utilisateur en remplissant ces champs. Une fois votre nom d’utilisateur et votre mot de passe saisis, vous pouvez accéder au menu principal. </p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve sur la page « Synthèse ». Sous le titre se trouve une liste de vidéos à côté de chacune desquelles se trouve un titre : « Élèves », « Personnel enseignant », « Classe » et « École ». L’écran change pour montrer les champs à remplir pour la création d’un nouveau compte. On y retrouve les champs suivants : « Nom d’utilisateur », « Mot de passe », « Adresse de courriel », « Courriel (confirmation) », « Prénom », « Nom » et « Ville ». Une flèche indique les cases « Nom d’utilisateur » et « Mot de passe », et l’écran de création de compte disparaît.</p>
<p><strong>Narrateur</strong></p>
<p>Les modules de formation sont répartis en cinq thèmes. Chaque thème inclut une vidéo qui en résume le contenu. Vous êtes invité à la regarder avant et après la réalisation des modules.</p>
<p><strong>Description visuelle</strong></p>
<p>On se retrouve à l’écran principal, avec une liste de cinq éléments en rouge, soit : « Élèves », « Personnel enseignant », « Classe », « École » et « École-famille-communauté ». Ils sont suivis de deux autres éléments en blanc, soit : « Journal d’une enseignante » et « Boîte à outils ». Le curseur clique sur « Élèves ». À l’écran s’affiche la vidéo relative au thème ainsi qu’une liste des modules de formation de ce thème.</p>
<p><strong>Narrateur</strong></p>
<p>La boîte à outils rassemble les outils et les ressources imprimables consultés dans les modules.</p>
<p><strong>Description visuelle</strong></p>
<p>On revient à l’écran principal. Le curseur clique sur « Boîte à outils ». Une liste des outils et ressources imprimables s’affiche.</p>
<p><strong>Narrateur</strong></p>
<p>Au cours de cette formation, vous consulterez des extraits du journal de Marie-Ève, une enseignante qui plonge dans sa première année de pratique au sein d’une école de langue française en contexte minoritaire. Vous trouverez l’intégralité de son journal ici.</p>
<p><strong>Description visuelle</strong></p>
<p>On revient à l’écran principal. Le curseur se pose sur « Journal d’une enseignante » pour indiquer où il faut cliquer pour accéder au journal de Marie-Ève.</p>
<p><strong>Narrateur</strong></p>
<p>Chaque thème propose quelques modules de formation. Tous les modules de formation reposent sur la même démarche pédagogique : l’amorce, le contenu et le bilan.</p>
<p><strong>Description visuelle</strong></p>
<p>Le curseur clique sur le thème « Personnel enseignant », puis sur l’un des modules de formation. Au haut de l’écran se trouve un ruban présentant trois onglets qui représentent les démarches pédagogiques mentionnées : « Amorce », « Contenu » et « Bilan ».</p>
<p><strong>Narrateur</strong></p>
<p>L’amorce, qui vous informe sur le contenu du module au cours d’une présentation, précise les résultats d’apprentissage visés. À cette étape, vous serez également invité à vous demander ce que vous savez déjà en consultant un extrait du journal de Marie-Ève. Vous pouvez écouter les extraits du journal ou les lire en accédant au verbatim.</p>
<p><strong>Description visuelle</strong></p>
<p>L’onglet du ruban qui est sélectionné est « Amorce ». À l’écran se trouve le titre « Présentation » avec un petit texte au bas duquel se trouvent les mots « Résultats d’apprentissage ». Le curseur clique sur ces mots-ci et on passe à une page avec le titre « Résultats d’apprentissage ». En dessous du texte qui accompagne le titre se trouvent les mots « Ce que je sais déjà ». Le curseur clique sur ces mots-ci et on passe à une page avec le titre « Ce que je sais déjà ». À l’écran se trouve à présent une bande sonore où on peut écouter le journal de Marie-Ève en cliquant sur le bouton de mise en marche, ce que démontre le narrateur avec son curseur. Le curseur clique ensuite sur le mot « Verbatim » sous la bande sonore, et la transcription audio du journal s’affiche à l’écran.</p>
<p><strong>Narrateur</strong></p>
<p>Le contenu présente les notions théoriques. En cliquant sur les pastilles rouges, consultez des informations et des exemples d’application afin de choisir l’intervention qui répond le mieux à votre contexte de pratique. Le nombre de pages de chaque section est indiqué au pied de la page-écran. Cliquez sur les pointeurs blancs ou les points d’interrogation pour prendre connaissance de l’information nécessaire à la réalisation des activités. Imprimez les documents en format PDF si vous le désirez. Le texte en gras et en bleu vous signale la présence d’informations à lire.</p>
<p><strong>Description visuelle</strong></p>
<p>Le curseur clique sur le prochain onglet du ruban, « Contenu », puis sur le sous-élément « Notions théoriques ». À l’écran se trouve une image sur laquelle des pastilles rouges affichent des mots. Le curseur clique sur une des pastilles, et un texte s’affiche à l’écran. En dessous de l’image est affichée une série de chiffres qui démontrent le nombre de pages qu’il y a. Le curseur clique sur la sixième page. Le curseur clique sur un pointeur blanc qui se trouve à l’intérieur d’un encadré de couleur à l’écran, puis sur un point d’interrogation rouge sur le côté gauche de l’écran, ce qui fait apparaître une boîte de texte. Le curseur clique sur un lien au bas de l’écran à côté duquel se trouve le logo d’un document PDF. On est amené à l’écran d’aperçu d’impression. Le curseur clique sur l’icône de l’imprimante pour imprimer le document en question. On revient à l’écran de l’onglet « Amorce ». Dans le texte sous le titre « Présentation », le curseur se pose sur un mot en gras et en bleu. Une bulle apparaît avec des informations à lire.</p>
<p><strong>Narrateur</strong></p>
<p>Chaque module comporte différentes activités d’apprentissage en lien avec votre pratique. Deux chemins vous permettent d’y accéder : le pointeur gris au pied de chacune des pages-écrans et l’onglet « Activités d’apprentissage » dans le menu de la barre d’outils. </p>
<p><strong>Description visuelle</strong></p>
<p>Le curseur clique sur l’onglet « Contenu » du ruban, puis sur le sous-élément « Activités d’apprentissage ». À l’écran se trouvent un tableau et les diverses activités d’apprentissage mentionnées. Le curseur montre le premier chemin qui permet d’y accéder, soit les mots « Activités d’apprentissage » à côté d’un pointeur gris au bas de la page-écran. Il montre ensuite le deuxième chemin en cliquant de nouveau sur l’onglet « Contenu », puis sur « Activités d’apprentissage ».</p>
<p><strong>Narrateur</strong></p>
<p>À la fin de la section « Contenu » de chaque module, les stratégies sont résumées.</p>
<p><strong>Description visuelle</strong></p>
<p>Le curseur clique de nouveau sur l’onglet « Contenu » du ruban, puis sur le sous-élément « Stratégies ».</p>
<p><strong>Narrateur</strong></p>
<p>Chaque module se termine par un bilan de vos apprentissages. Vous êtes appelé à réviser ce que vous avez appris, à consulter la synthèse et à faire une auto-évaluation. Pour en savoir plus, de multiples ressources vous sont proposées : des textes, des vidéos, une bibliographie et une webographie.</p>
<p><strong>Description visuelle</strong></p>
<p>Le curseur clique sur l’onglet « Bilan » du ruban. Un menu contextuel apparaît avec les options suivantes : « Ce que j’ai appris », « Synthèse », « Autoévaluation » et « Pour en savoir + ». Le curseur clique sur « Pour en savoir + » et nous amène à cet écran, où est affichée une liste de ressources à consulter.</p>
<p><strong>Narrateur</strong></p>
<p>Grâce à la boîte à outils, à laquelle vous accédez aussi en cliquant sur la pastille « BAO », vous pouvez voir tous les documents imprimables consultés au cours de votre parcours de formation. Par exemple, les aide-mémoire, les aides à la planification ou encore les tableaux-synthèses. Il est à noter que la boîte à outils propose une feuille de route vous permettant de noter les modules que vous avez terminés. Cette boîte à outils contient aussi des supports visuels que vous pouvez imprimer et afficher en classe et un dépliant pour les parents visant à stimuler la réussite scolaire de leur enfant.</p>
<p><strong>Description visuelle</strong></p>
<p>On revient à la page d’accueil. L’élément « Boîte à outils » est encerclé. Le curseur indique la pastille « BAO » dans le coin supérieur droit de l’écran. En cliquant dessus, on nous amène à la page de la boîte à outils avec la liste des documents nommés par le narrateur. Le curseur clique sur « Feuille de route ». À l’écran se trouve une liste dont on peut cocher les éléments dans des petites cases à cet effet. On revient à la page de la boîte à outils. Le curseur clique sur « Supports visuels ». À l’écran s’affiche une liste d’hyperliens vers des supports visuels.</p>
<p><strong>Narrateur</strong></p>
<p>Enfin, le journal de l’enseignante Marie-Ève vous fournit des pistes de réflexion sur votre pratique et des interventions pédagogiques à même de favoriser la réussite scolaire des élèves!</p>
<p><strong>Description visuelle</strong></p>
<p>On revient à la page d’accueil. Le curseur glisse sur « Journal d’une enseignante ». Lorsque finit de parler le narrateur, l’écran devient blanc et le mot SOFAD superposé sur un carré rouge apparaît au-dessus de l’adresse de courriel www.sofad.qc.ca.</p>
`


    return (
      <>
        <Header />
        <main className="VideoPage">
          <Row>



            <Col xs="12" xl="9" className="mx-auto mb-4">
              <div className="inner-div pl-4">
              <h1>Vidéo tutoriel de l'autoformation <em>Communico</em>, durée&nbsp;: 4&nbsp;min&nbsp;39&nbsp;s</h1>
              </div>
            </Col>



            <Col xs="12" xl="8" className="ml-auto mb-4">
              <AblePlayerComponent src='Ressources/Ateliers/Visite_guidee_Communico.mp4' captionSrc='Ressources/Ateliers/Communico_narration.txt' videoDescription={vidDesc} poster="img/communicoVideo.png"/>
            </Col>



            <Col xs="12" xl="1" className="mr-auto mt-3">
              <div id="sticky-social-media-right" className="social-media-widget-right-side">
              <ul>
                      <li>
                          <a href={"https://www.facebook.com/sharer/sharer.php?u="+encodeURI(window.location.href)} target="_blank" class="facebook" title="Partager sur Facebook">
                              <span className="fab fa-facebook-f" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur Facebook. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"http://twitter.com/share?text=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;url="+encodeURI(window.location.href)} class="twitter" target="_blank" title="Partager sur Twitter">
                              <span className="fab fa-twitter" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur Twitter. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + encodeURI(window.location.href) + "&amp;title=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;summary=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;source=LinkedIn"} class="linkedin" target="_blank" title="Partager sur LinkedIn">
                              <span className="fab fa-linkedin-in" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur LinkedIn. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"mailto:?subject=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;body=Visitez%20la%20ressource%20J%27enseigne%20en%20fran%C3%A7ais%20au%20canada%20en%20acc%C3%A9dant%20au%20lien%20suivant%20%3A%20"+ encodeURI(window.location.href)} class="email" target="_blank" title="Partager par courriel">
                              <span className="fas fa-envelope" aria-hidden="true"></span>
                              <span className="sr-only">Partager par courriel. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      {/*<li>
                          <a href="http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fedusourceontario.com%2Fres%2Fmaths-apprendre-enseigner-innover&amp;media=%2F%2Fimages.ctfassets.net%2Fcfektv4t16rw%2F5kcLYdcNyYWrhWRVlm7Kfe%2F46dd6489ee132b4d0dd0929788f3bc21%2Fmath-app-ens-innov.png&amp;description=%C3%89l%C3%A9ments%20fondamentaux%20en%20math%C3%A9matiques" target="_blank" class="pinterest" title="Partager sur Pinterest">
                              <span class="fab fa-pinterest" aria-hidden="true"></span>
                              <span class="sr-only">Partager sur Pinterest. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>*/}
                  </ul>
                </div>
              </Col>




          </Row>
        </main>
        <Footer />
      </>
    );
  }
}
export default VideoVisiteGuideeCommunicoPage;
