import React from "react";
import {Component} from 'react'

import Header from "../Components/Header";
import Footer from "../Components/Footer";

class PlanDuSitePage extends Component {
  render() {
    return (
      <>
        <Header />
        <main className="planDuSitePage">
          <h1>Plan du site</h1>
          <ul className="inner-div pl-5">
            <li><a href="/">Accueil</a></li>
    				<li><a href="/Recherche">Recherche</a></li>
    				<li><a href="/Ateliers">Les ateliers</a>
              <ul>
                <li><a href="/VideoSite">Vidéo de présentation du site <em>J’enseigne en français au Canada</em>, durée&nbsp;: 1&nbsp;min&nbsp;29&nbsp;s</a></li>
                <li><a href="/AtelierEnseignant">Présentation courte, durée estimée : 30 min</a></li>
        				<li><a href="/AtelierLong">Présentation longue, durée estimée : 4 h</a></li>
                <li><a href="/AtelierParents">Présentation pour les parents, durée estimée : 30 min</a></li>
                <li><a href="/VideoCommunico">Vidéo tutoriel de l'autoformation <em>Communico</em>, durée : 4&nbsp;min&nbsp;39&nbsp;s</a></li>
                <li><a href="/VideoApprocheCulturelle">Vidéo tutoriel de l'autoformation <em>Approche culturelle de l’enseignement</em>, durée : 6&nbsp;min&nbsp;52&nbsp;s</a></li>
              </ul>
            </li>
    				<li><a href="/NousJoindre">Nous joindre</a></li>
    				<li><a href="https://lien.cforp.ca/24694">Votre avis</a></li>
    				<li><a href="/AutresRessources">Autres ressources</a></li>
          </ul>

        </main>
        <Footer />
      </>
    );
  }
}
export default PlanDuSitePage;
