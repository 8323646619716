import React from "react";
import {Component} from 'react'
import {Link} from "react-router-dom";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

class ErrorPage extends Component {
  render() {
    return (
      <>
        <Header />
        <main className="errorPage">

          <div className="inner-div">
          <p>Page introuvable<br/>
          <Link to="/">Retour à la page d'accueil</Link></p>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
export default ErrorPage;
