import React from "react";
import {Component} from 'react'
import {Row, Col} from 'react-bootstrap';

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import AblePlayerComponent from "../../Components/AblePlayerComponent"

class VideoVisiteGuideeCmecPage extends Component {
  render() {

    var vidDesc =`
<p><strong>Aide à la navigation :</strong> La vidéo présente en alternance les propos d’un narrateur et les éléments visuels de la vidéo qui sont porteurs de contenu.</p>
<p><strong>Narrateur</strong></p>
<p>Vous enseignez en français dans des écoles où le français est la langue première, mais dans un contexte minoritaire? Vous cherchez des ressources qui sont adaptées à votre réalité? Ceci est pour vous.</p>
<p>Saviez-vous qu’il existe au Canada un Conseil des ministres de l’Éducation (CMEC) et un Comité du Consortium du français langue première qui réalise, avec l’appui du gouvernement du Canada, des ressources éducatives qui tiennent compte des particularités de l’apprentissage et de l’enseignement du français en contexte minoritaire au Canada?</p>
<p><strong>Description visuelle</strong></p>
<p>Des scènes se succèdent à l’écran : de jeunes élèves et un enseignant regroupés autour d’une table où repose une maquette; une enseignante et quatre jeunes élèves en train de faire du jardinage en salle de classe; une enseignante qui parle à deux adolescents dans une bibliothèque; un enseignant qui tape dans la main d’un jeune élève assis devant un ordinateur; une enseignante qui lit un livre à un groupe de jeunes élèves en salle de classe; et un enseignant et ses élèves portant tous des lunettes de protection regroupés autour d’une table de laboratoire. Les mots « français langue première », « dans un contexte minoritaire » et « ressources adaptées à votre réalité » apparaissent à l’écran en même temps que les prononce le narrateur. Au haut de l’écran apparaît le logo du CMEC avec le nom Conseil des ministères de l’Éducation (Canada), et au bas de l’écran, le nom Comité du Consortium du français langue première. La scène change pour montrer une femme de dos devant un ordinateur portable qui écrit les mots suivants dans une barre de recherche : Ressources + FLP + contexte minoritaire. Elle clique sur « Rechercher » et le premier lien qui apparaît est le site Web de <em>J’enseigne en français au Canada</em>.</p>
<p><strong>Narrateur</strong></p>
<p><em>J’enseigne en français au Canada</em> est votre porte d’entrée pour accéder à ces ressources.</p>
<p>Vous connaissez peut-être déjà l’autoformation <em>Communico</em>, portant sur la communication orale, ou l’autoformation <em>Approche culturelle de l’enseignement</em>.</p>
<p>Inscrivez-vous dès maintenant!</p>
<p>Un outil de recherche simple qui vous permet de consulter directement une soixantaine de documents et d’activités tirés de <em>Communico</em>, de l’<em>Approche culturelle de l’enseignement</em> et éventuellement des futurs projets du Consortium conçus pour vous.</p>
<p><strong>Description visuelle</strong></p>
<p>Le logo de <em>J’enseigne en français au Canada</em> apparaît à l’écran, puis une page du site Web s’affiche. Sur le côté gauche de la page se trouve une vidéo sous le titre « Découvrez les ressources ». Sur le côté droit se trouvent des menus pour faire une recherche. Sous ces menus apparaissent deux autoformations, soit <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>. La souris à l’écran clique sur le premier menu contextuel où l’on demande « Quel type de professionnel êtes-vous? ». Parmi les choix du menu, la souris sélectionne « Personnel enseignant ». La souris clique ensuite sur le deuxième menu contextuel où l’on demande « Quels sont vos besoins? » et sélectionne « Français langue première » parmi les choix. La souris clique sur le bouton « Allez-y! » pour lancer la recherche. Les résultats qui apparaissent se rapportent tous à l’autoformation <em>Communico</em>.</p>
<p><strong>Narrateur</strong></p>
<p>Ajoutez <em>J’enseigne en français au Canada</em> à vos favoris. Faites-en part à vos collègues!</p>
<p><strong>Description visuelle</strong></p>
<p>À l’écran se trouve le titre « Favori ajouté » au-dessus d’une barre de recherche dans laquelle est tapée l’adresse jenseigne-en-français.ca. La souris clique sur l’étoile à côté du site pour l’ajouter aux favoris. L’écran change pour montrer le titre « Partagez! » Au-dessus des logos de Facebook, Twitter, YouTube et LinkedIn. À l’écran sont tapés les mots « Une réalisation du Comité du Consortium du français langue première (CCFLP), 2019 ».</p>
`

    return (
      <>
        <Header />
        <main className="VideoPage">
          <Row>



            <Col xs="12" xl="9" className="mx-auto mb-4">
              <div className="inner-div pl-4">
              <h1>Vidéo de présentation du site <em>J’enseigne en français au Canada</em>, durée&nbsp;: 1&nbsp;min&nbsp;29&nbsp;s</h1>
              </div>
            </Col>



            <Col xs="12" xl="8" className="ml-auto mb-4">
                <AblePlayerComponent src="https://player.vimeo.com/external/395966447.sd.mp4?s=2ed3710ff683720162232d29ebda42417a1486ac&profile_id=165" captionSrc="Ressources/Ateliers/Video-promo-sous-titrage.txt" videoDescription={vidDesc} poster="img/ressourceVideo.png"/>
            </Col>



            <Col xs="12" xl="1" className="mr-auto mt-3">
              <div id="sticky-social-media-right" className="social-media-widget-right-side">
              <ul>
                      <li>
                          <a href={"https://www.facebook.com/sharer/sharer.php?u="+encodeURI(window.location.href)} target="_blank" class="facebook" title="Partager sur Facebook">
                              <span className="fab fa-facebook-f" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur Facebook. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"http://twitter.com/share?text=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;url="+encodeURI(window.location.href)} class="twitter" target="_blank" title="Partager sur Twitter">
                              <span className="fab fa-twitter" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur Twitter. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + encodeURI(window.location.href) + "&amp;title=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;summary=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;source=LinkedIn"} class="linkedin" target="_blank" title="Partager sur LinkedIn">
                              <span className="fab fa-linkedin-in" aria-hidden="true"></span>
                              <span className="sr-only">Partager sur LinkedIn. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      <li>
                          <a href={"mailto:?subject=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;body=Visitez%20la%20ressource%20J%27enseigne%20en%20fran%C3%A7ais%20au%20canada%20en%20acc%C3%A9dant%20au%20lien%20suivant%20%3A%20"+ encodeURI(window.location.href)} class="email" target="_blank" title="Partager par courriel">
                              <span className="fas fa-envelope" aria-hidden="true"></span>
                              <span className="sr-only">Partager par courriel. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>
                      {/*<li>
                          <a href="http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fedusourceontario.com%2Fres%2Fmaths-apprendre-enseigner-innover&amp;media=%2F%2Fimages.ctfassets.net%2Fcfektv4t16rw%2F5kcLYdcNyYWrhWRVlm7Kfe%2F46dd6489ee132b4d0dd0929788f3bc21%2Fmath-app-ens-innov.png&amp;description=%C3%89l%C3%A9ments%20fondamentaux%20en%20math%C3%A9matiques" target="_blank" class="pinterest" title="Partager sur Pinterest">
                              <span class="fab fa-pinterest" aria-hidden="true"></span>
                              <span class="sr-only">Partager sur Pinterest. Ouvre dans un nouvel onglet.</span>
                          </a>
                      </li>*/}
                  </ul>
                </div>
              </Col>




          </Row>
        </main>
        <Footer />
      </>
    );
  }
}
export default VideoVisiteGuideeCmecPage;
