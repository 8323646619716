import React from "react";
import {Component} from 'react'

import {Row, OverlayTrigger} from 'react-bootstrap';

import Header from "../Components/Header";
import Footer from "../Components/Footer";

class AteliersPage extends Component {

  render() {
    const renderTooltip = props => (
      <div className="OverlayTrigger"
        {...props}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          padding: '2px 10px',
          color: 'white',
          borderRadius: 3,
          ...props.style,
        }}
      >
      Version téléchargeable
      </div>
    );



    return (<>
      <Header/>
      <main className="AteliersPage">

      <h1>Les ateliers</h1>

      <div className="inner-div pb-2 mb-3">
        <p>
          <strong>Utilisation et présentation du site et des ressources</strong>
        </p>

        <p>Conscients que plusieurs d’entre vous contribuent à faire connaître les ressources produites par le CMEC et son Consortium du français langue première, de nombreux documents de présentation ont été conçus et créés afin de faciliter votre travail de communication. Vous trouverez donc ci-dessous des vidéos et des présentations en ligne et PowerPoint de durées variées, adaptées à différents contextes.</p>



      </div>
      <Row>
        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/vignettes-video-promo.jpg" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Vidéo de présentation du site <em>J’enseigne en français au Canada</em>, durée&nbsp;: 1&nbsp;min&nbsp;29&nbsp;s</h2>
                    <div className="card-text">
                      <p>Utilisez cette courte vidéo dynamique pour présenter le site et ses ressources.</p>
                    </div>
                  <div class="text-right align-bottom-right">
                    <a href="/VideoSite" class="btn btn-primary">Visionner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/atelier-court-30min.jpg" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Présentation courte, durée estimée&nbsp;: 30&nbsp;min</h2>
                  <div className="card-text">
                    <p>Cette présentation est idéale pour les enseignants au moment d’ateliers, de congrès ou de réunions du personnel ou de direction. On y présente de façon sommaire les deux ressources <em>Approche culturelle de l’enseignement</em> et <em>Communico</em>. Elle est disponible en ligne et aussi en format téléchargeable sur PowerPoint.</p>
                    <p class="smallText"><em>Vous pouvez consulter l'aperçu en ligne ou télécharger une version des présentations en format PowerPoint. Les versions PowerPoint sont éditables et contiennent des consignes utiles pour le présentateur.</em></p>
                  </div>
                <div class="text-right align-bottom-right">
                    <a href="/AtelierEnseignant" class="btn btn-primary">Aperçu</a>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={renderTooltip}>
                      <a href="Ressources/Ateliers/atelier-ens-30-minutes.pptx" class="btn btn-primary ml-3"><span class="far fa-file-powerpoint"></span><span className="sr-only">Version téléchargeable PowerPoint</span></a>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/atelier-long-4h.jpg" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Présentation longue, durée estimée&nbsp;: 4&nbsp;h</h2>
                  <div className="card-text">
                    <p>Utilisez cette présentation ou une partie de celle-ci dans le cadre d’un atelier substantiel au cours de <strong>journées pédagogiques</strong>. On y présente en détail les deux ressources <em>Approche culturelle de l’enseignement</em> et <em>Communico</em>. Elle est disponible en ligne et aussi en format téléchargeable sur PowerPoint.</p>
                      <p class="smallText"><em>Vous pouvez consulter l'aperçu en ligne ou télécharger une version des présentations en format PowerPoint. Les versions PowerPoint sont éditables et contiennent des consignes utiles pour le présentateur.</em></p>
                  </div>
                <div class="text-right align-bottom-right">
                    <a href="/AtelierLong" class="btn btn-primary">Aperçu</a>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={renderTooltip}>
                      <a href="Ressources/Ateliers/atelier-long-4-h.pptx" class="btn btn-primary ml-3"><span class="far fa-file-powerpoint"></span><span className="sr-only">Version téléchargeable PowerPoint</span></a>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/atelier-parents-30min.jpg" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Présentation pour les parents, durée estimée&nbsp;: 30&nbsp;min</h2>
                  <div className="card-text">
                    <p>Cette présentation est idéale pour utiliser lors des réunions de <strong>parents</strong>. On y présente de façon sommaire les deux ressources <em>Approche culturelle de l’enseignement</em> et <em>Communico</em>. Elle est disponible en ligne et aussi en format téléchargeable sur PowerPoint.</p>
                      <p class="smallText"><em>Vous pouvez consulter l'aperçu en ligne ou télécharger une version des présentations en format PowerPoint. Les versions PowerPoint sont éditables et contiennent des consignes utiles pour le présentateur.</em></p>
                  </div>
                <div class="text-right align-bottom-right">
                    <a href="/AtelierParents" class="btn btn-primary">Aperçu</a>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={renderTooltip}>
                      <a href="Ressources/Ateliers/atelier-parents-30 minutes.pptx" class="btn btn-primary ml-3"><span class="far fa-file-powerpoint"></span><span className="sr-only">Version téléchargeable PowerPoint</span></a>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/video2.png" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Vidéo tutoriel de l'autoformation <em>Communico</em>, durée&nbsp;: 4&nbsp;min&nbsp;39&nbsp;s</h2>
                    <div className="card-text">
                      <p>Utilisez cette courte vidéo afin d'apprendre et de présenter le mode d'utilisation de l'autoformation <em>Communico</em>.</p>
                    </div>
                  <div class="text-right align-bottom-right">
                    <a href="/VideoCommunico" class="btn btn-primary">Visionner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/video3.png" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Vidéo tutoriel de l'autoformation <em>Approche culturelle de l’enseignement</em>, durée&nbsp;: 6&nbsp;min&nbsp;52&nbsp;s</h2>
                    <div className="card-text">
                      <p>Utilisez cette courte vidéo afin d'apprendre et de présenter le mode d'utilisation de l'autoformation <em>Approche culturelle de l’enseignement</em>.</p>
                    </div>
                  <div class="text-right align-bottom-right">
                    <a href="/VideoApprocheCulturelle" class="btn btn-primary">Visionner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="col-12 col-xl-6 pb-3">
          <div className="card h-100">
            <div className="row no-gutters h-100">
              <div className="col-md-4">
                <img src="img/VideoTutoriel.jpg" className="card-img" alt=""/>
              </div>
              <div className="col-md-8">
                <div className="card-body h-100">
                  <h2 className="card-title">Vidéo tutoriel <em>J'enseigne en français au Canada</em>, durée&nbsp;: 5&nbsp;min&nbsp;50&nbsp;s</h2>
                    <div className="card-text">
                      <p>Pour de l'aide sur comment utiliser efficacement le site <em>J'enseigne en français au Canada</em>, vous pouvez visionner le tutoriel suivant</p>
                    </div>
                  <div class="text-right align-bottom-right">
                    <a href="/VideoTutoriel" class="btn btn-primary">Visionner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Row>

    </main>
    <Footer/>
  </>);
  }
}
export default AteliersPage;
