import React from "react";
import {Component} from 'react'
import {Row, Col} from 'react-bootstrap';

import {connect} from 'react-redux'

import Header from "../Components/Header";
//import Footer from "../Components/Footer";

class RessourcePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Url: "",
      Titre:""
    };
  }

  componentDidMount(){
    if(this.props.MatchRessources.length === 1){
            this.props.history.replace()
            var url = this.props.MatchRessources[0].Url

      this.setState({
          Url:url,
          Titre:this.props.MatchRessources[0].Titre
      })
    }else{
      this.props.history.push('/404')
    }
  }




  render() {
    return (
      <>
        {this.props.MatchRessources[0].LoginNeeded?
          <Header showRefreshBtn={true}/>:
          <Header />
        }




                                <Col xs="12" className="mr-auto mt-3 text-right">
                                  <div id="sticky-social-media-right" className="social-media-widget-right-side ressources-media-widget">
                                  <ul>
                                          <li>
                                              <a href={"https://www.facebook.com/sharer/sharer.php?u="+encodeURI(window.location.href)} target="_blank" class="facebook" title="Partager sur Facebook">
                                                  <span className="fab fa-facebook-f" aria-hidden="true"></span>
                                                  <span className="sr-only">Partager sur Facebook. Ouvre dans un nouvel onglet.</span>
                                              </a>
                                          </li>
                                          <li>
                                              <a href={"http://twitter.com/share?text=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;url="+encodeURI(window.location.href)} class="twitter" target="_blank" title="Partager sur Twitter">
                                                  <span className="fab fa-twitter" aria-hidden="true"></span>
                                                  <span className="sr-only">Partager sur Twitter. Ouvre dans un nouvel onglet.</span>
                                              </a>
                                          </li>
                                          <li>
                                              <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + encodeURI(window.location.href) + "&amp;title=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;summary=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;source=LinkedIn"} class="linkedin" target="_blank" title="Partager sur LinkedIn">
                                                  <span className="fab fa-linkedin-in" aria-hidden="true"></span>
                                                  <span className="sr-only">Partager sur LinkedIn. Ouvre dans un nouvel onglet.</span>
                                              </a>
                                          </li>
                                          <li>
                                              <a href={"mailto:?subject=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;body=Visitez%20la%20ressource%20J%27enseigne%20en%20fran%C3%A7ais%20au%20canada%20en%20acc%C3%A9dant%20au%20lien%20suivant%20%3A%20"+ encodeURI(window.location.href)} class="email" target="_blank" title="Partager par courriel">
                                                  <span className="fas fa-envelope" aria-hidden="true"></span>
                                                  <span className="sr-only">Partager par courriel. Ouvre dans un nouvel onglet.</span>
                                              </a>
                                          </li>
                                          {/*<li>
                                              <a href="http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fedusourceontario.com%2Fres%2Fmaths-apprendre-enseigner-innover&amp;media=%2F%2Fimages.ctfassets.net%2Fcfektv4t16rw%2F5kcLYdcNyYWrhWRVlm7Kfe%2F46dd6489ee132b4d0dd0929788f3bc21%2Fmath-app-ens-innov.png&amp;description=%C3%89l%C3%A9ments%20fondamentaux%20en%20math%C3%A9matiques" target="_blank" class="pinterest" title="Partager sur Pinterest">
                                                  <span class="fab fa-pinterest" aria-hidden="true"></span>
                                                  <span class="sr-only">Partager sur Pinterest. Ouvre dans un nouvel onglet.</span>
                                              </a>
                                          </li>*/}
                                      </ul>
                                    </div>
                                  </Col>




        <main className="ressourcePage">
              <iframe className="RessourceIframe" title={'Ressource '+ this.state.Titre} id="RessourceIframe" src={this.state.Url}></iframe>
        </main>
      </>
    );
  }
}


function mapStateToProps(state, ownProps){
    return{
      MatchRessources : state.RessourceReducer.filter(x => x.UniqueKey === ownProps.match.params.UniqueKey)
    };
}

export default connect(mapStateToProps)(RessourcePage);
