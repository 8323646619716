import React from 'react';
import {Component} from 'react'
import {Router, Route, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";

import HomePage from './Pages/HomePage';
import SaviezVousPage from './Pages/SaviezVousPage';
import NousJoindrePage from './Pages/NousJoindrePage';
import ListeRessourcesPages from './Pages/ListeRessourcesPages'
import RessourcePage from './Pages/RessourcePage'
import PlanDuSitePage from './Pages/PlanDuSitePage'
import AteliersPage from './Pages/AteliersPage'
import PresentationAtelierLongPage from './Pages/Presentation/AtelierLongPage'
import PresentationAtelierEnseignantPage from './Pages/Presentation/AtelierEnseignantPage'
import PresentationAtelierParentsPage from './Pages/Presentation/AtelierParentsPage'

import VideoVisiteGuideeCommunicoPage from './Pages/Presentation/VideoVisiteGuideeCommunicoPage'
import VideoVisiteGuideeCmecPage from './Pages/Presentation/VideoVisiteGuideeCmecPage'
import VideoVisiteGuideeApprocheCulturellePage from './Pages/Presentation/VideoVisiteGuideeApprocheCulturellePage'
import VideoTutorielPage from './Pages/Presentation/VideoTutorielPage'

import AutresRessourcesPage from './Pages/AutresRessourcesPage'
import BesoinDeSuggestionsPage from './Pages/BesoinDeSuggestionsPage'
import ErrorPage from './Pages/ErrorPage';

import Container from 'react-bootstrap/Container';

import './App.css';


const history = createBrowserHistory()


class App extends Component {
  render(){
    return (
		<Container fluid="true" className="px-3 px-md-4 px-lg-5">
		  <Router history={history}>
			<Switch>
				<Route exact path="/" component={HomePage}/>
				<Route path="/SaviezVous" component={SaviezVousPage}/>
				<Route path="/NousJoindre" component={NousJoindrePage}/>
				<Route path="/Ressource/:UniqueKey" component={RessourcePage}/>
				<Route path="/Recherche" component={ListeRessourcesPages}/>
				<Route path="/PlanDuSite" component={PlanDuSitePage}/>
				<Route path="/Ateliers" component={AteliersPage}/>
				<Route path="/AtelierLong" component={PresentationAtelierLongPage}/>
        <Route path="/AtelierEnseignant" component={PresentationAtelierEnseignantPage}/>
        <Route path="/AtelierParents" component={PresentationAtelierParentsPage}/>
        <Route path="/VideoSite" component={VideoVisiteGuideeCmecPage}/>
        <Route path="/VideoCommunico" component={VideoVisiteGuideeCommunicoPage}/>
        <Route path="/VideoApprocheCulturelle" component={VideoVisiteGuideeApprocheCulturellePage}/>
        <Route path="/VideoTutoriel" component={VideoTutorielPage}/>
				<Route path="/AutresRessources" component={AutresRessourcesPage}/>
        {/*<Route path="/BesoinDeSuggestionsPage" component={BesoinDeSuggestionsPage}/>*/}
				<Route component={ErrorPage} />
			</Switch>
		  </Router>
		</Container>
    );
  }
}

export default App;
