import {combineReducers} from 'redux';
import SaviezVousReducer from './SaviezVousReducer';
import TemoignageReducer from './TemoignageReducer';
import RessourceReducer from './RessourceReducer';
import FormatReducer from './FormatReducer'
import TypeDeProfessionelReducer from './TypeDeProfessionelReducer';
import BesoinReducer from './BesoinReducer';

import FilterReducer from './FilterReducer';


const RootReducers = combineReducers({
    SaviezVousReducer,
    TemoignageReducer,
    FormatReducer,
    RessourceReducer,
    TypeDeProfessionelReducer,
    BesoinReducer,
    FilterReducer
});

export default RootReducers
