import React, {Component} from "react";
import {Row, Col} from 'react-bootstrap';
import $ from "jquery"
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

class AtelierParentsPage extends Component {


  componentDidMount(){
    var $carousel= $(".carousel")
    $carousel.children('.carousel-control-prev').hide();
    $carousel.children(".prevCarousel").show();
    $carousel.children('.carousel-control-next').show();
    $carousel.children(".nextCarousel").hide();
  }

  render() {
    function handleClick(e){
      var $this = $(e.target).parents(".carousel");
      setTimeout(function(){
        if ($this.find('.carousel-item:first-child').hasClass('active')) {
            $this.children('.carousel-control-prev').hide();
            $this.children('.carousel-control-next').show();
            $this.children(".prevCarousel").show();
            $this.children(".nextCarousel").hide();
        } else if ($this.find('.carousel-item:last-child').hasClass('active')) {
            $this.children('.carousel-control-prev').show();
            $this.children('.carousel-control-next').hide();
            $this.children(".prevCarousel").hide();
            $this.children(".nextCarousel").show();
        } else {
            $this.children('.carousel-control-prev').show();
            $this.children('.carousel-control-next').show();
            $this.children(".prevCarousel").hide();
            $this.children(".nextCarousel").hide();
        }
      }, 620, $this);
    }
    return (
      <>
        <Header />
        <main className="atelier">

          <h1>Présentation pour les parents</h1>

          <div className="accordion" id="accordionExample">
            <button id="headingOne" className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <h2>Introduction</h2>
            </button>
            <button id="headingTwo" className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <h2>Communico</h2>
            </button>
            <button id="headingThree" className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <h2>Approche culturelle de l’enseignement</h2>
            </button>
            <button id="headingFour" className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <h2>Appréciation</h2>
            </button>


            {/* ********************************************* */
            /* **************** Plan du site *************** */
            /* ********************************************* */}

              <div id="collapseOne" className="card collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div className="card-body">


                  <div id="carouselPlan" className="carousel slide" data-ride="carousel" data-interval="false"  data-wrap="false">
                      <ol className="carousel-indicators" onClick={handleClick}>
                        <li data-target="#carouselPlan" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselPlan" data-slide-to="1"></li>
                        <li data-target="#carouselPlan" data-slide-to="2"></li>
                      </ol>
                      <div className="carousel-inner">
                        {/* **************** Slide 1.1 *************** */}
                        <div className="carousel-item active">
                          <Row>
                            <Col xs="12" xl="6" className="img firstSlide">
                                <img
                                  className="d-block w-100"
                                  src="img/atelier-parents-30min-grande.jpg"
                                  alt=""
                                />
                            </Col>
                            <Col xs="12" xl="6" className="text firstSlide">
                              <h3>Atelier</h3>
                              <p>Destiné aux parents</p>

                              <div className="carreGris">
                                <p>La communication orale et la construction identitaire de votre enfant sont une <strong>priorité</strong> dans l’enseignement en français langue première en contexte minoritaire.</p>
                              </div>

                            </Col>
                          </Row>
                        </div>
                        {/* **************** Slide 1.2 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col className="titre">
                              <h3><em>J’enseigne en français au Canada</em></h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" xl="6" className="img">
                              <img
                                className="d-block w-100"
                                src="img/AtelierParent/1.2.jpg"
                                alt=""
                              />
                            </Col>
                            <Col xs="12" xl="6" className="text">
                              <div className="carreGris">
                                <p><em>J’enseigne en français au Canada</em> est la <strong>porte d’entrée</strong> pour découvrir les ressources en français pour appuyer votre enfant dans ses apprentissages.</p>
                                <p><em>Cette ressource est produite avec le soutien financier des provinces et des territoires membres du CCFLP et du gouvernement du Canada.</em></p>
                              </div>

                            </Col>
                          </Row>

                        </div>
                        {/* **************** Slide 1.3 *************** */}
                        <div className="carousel-item">
                            <Row>
                              <Col className="titre">
                                <h3>Pour appuyer le personnel enseignant et pour vous, les parents... </h3>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" xl="6" className="img">
                                <img
                                  className="d-block w-100"
                                  src="img/AtelierParent/1.3.jpg"
                                  alt=""
                                />
                              </Col>
                              <Col xs="12" xl="6" className="text">
                                <div className="carreGris">
                                  <p>Deux sites intéressants :</p>
                                  <ul>
                                    <li><em><strong>Communico</strong></em></li>
                                    <li><em><strong>Approche culturelle de l'enseignement</strong></em></li>
                                  </ul>
                                  <p>Le site <em>J’enseigne en français au Canada</em> interagit et mène aux sites <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>.</p>
                                </div>
                              </Col>
                            </Row>
                        </div>

                      </div>

                      <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-atelier-parents-30m.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                      <a className="carousel-control-prev" href="#carouselPlan" role="button" data-slide="prev" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                        <span className="sr-only">Précédent</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselPlan" role="button" data-slide="next" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Suivant</span>
                      </a>
                      <button className="carousel-control-next nextCarousel" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Approche culturelle de l’enseignement</span>
                      </button>
                    </div>


                </div>
              </div>


          {/************* Approche culturelle de l'enseignant *************/}

            <div id="collapseTwo" className="card collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div className="card-body">

                <div id="carouselApproche" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                  <ol className="carousel-indicators" onClick={handleClick}>
                    <li data-target="#carouselApproche" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselApproche" data-slide-to="1"></li>
                    <li data-target="#carouselApproche" data-slide-to="2"></li>
                    <li data-target="#carouselApproche" data-slide-to="3"></li>
                    <li data-target="#carouselApproche" data-slide-to="4"></li>
                    <li data-target="#carouselApproche" data-slide-to="5"></li>
                  </ol>
                  <div className="carousel-inner">

                    {/* **************** Slide 2.1 *************** */}
                    <div className="carousel-item active">
                      <Row>
                          <Col className="titre">
                            <h3>S’approprier le contenu de <em>Communico</em></h3><br />
                            <a href="http://communico.club" target="_blank" rel="noopener noreferrer">http://communico.club</a>
                          </Col>
                      </Row>
                      <Row>
                        <Col xs="12" xl="6" className="img">
                          <a className="imgLink" href="https://communico.club/mod/standalone/content/22/index.php/contenu/lire/page/page-principale.html" aria-label="Site externe Communico - Page d'accueil" target="_blank" rel="noopener noreferrer">
                            <img className="d-block w-100" src="img/AtelierParent/2.1.jpg" alt="" />
                          </a>
                        </Col>
                        <Col xs="12" xl="6" className="text">
                        <div className="carreGris">
                          <p><strong>Tout ce qu’il faut savoir sur la communication orale.</strong></p>
                          <p>Les parents peuvent suivre l’autoformation ou tout simplement fureter pour trouver de l’information et des ressources.</p>
                        </div>
                        </Col>
                      </Row>
                    </div>

                    {/* **************** Slide 2.2 *************** */}
                    <div className="carousel-item">
                      <Row>
                        <Col className="titre">
                          <h3>Explorons un module : l’élève</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" xl="6" className="img">
                          <a className="imgLink" href="Ressource/C19" aria-label="L'élève qui fréquente une école francophone en contexte minoritaire" target="_blank" rel="noopener noreferrer">
                            <img className="d-block w-100" src="img/AtelierParent/2.2.jpg" alt="" />
                          </a>
                        </Col>
                        <Col xs="12" xl="6" className="text">
                        <div className="carreGris">
                          <p>Des informations essentielles sur la communication orale</p>
                        </div>
                        </Col>
                      </Row>
                    </div>

                    {/* **************** Slide 2.3 *************** */}
                    <div className="carousel-item">
                      <Row>
                        <Col className="titre">
                          <h3>Votre enfant s’exprime-t-il bien à l’oral?</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" xl="6" className="img">
                          <a className="imgLink" href="Ressource/C02" aria-label="Le continuum langagier" target="_blank" rel="noopener noreferrer">
                            <img className="d-block w-100" src="img/AtelierParent/2.3.jpg" alt="" />
                          </a>
                        </Col>
                        <Col xs="12" xl="6" className="text">
                        <div className="carreGris">
                          <p>Explorons le continuum langagier!</p>
                        </div>
                        </Col>
                      </Row>
                    </div>

                    {/* **************** Slide 2.4 *************** */}
                    <div className="carousel-item">
                      <Row>
                        <Col className="titre">
                          <h3>L’école, la famille et la communauté : des partenaires</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" xl="6" className="img">
                          <a className="imgLink" href="Ressource/C27" aria-label="École-famille-communauté" target="_blank" rel="noopener noreferrer">
                            <img className="d-block w-100" src="img/AtelierParent/2.4.jpg" alt="" />
                          </a>
                        </Col>
                        <Col xs="12" xl="6" className="text">
                        <div className="carreGris">
                          <p>Cette section contient d’autres informations pertinentes pour les parents.</p>
                        </div>
                        </Col>
                      </Row>
                    </div>

                    {/* **************** Slide 2.5 *************** */}
                    <div className="carousel-item">
                      <Row>
                        <Col className="titre">
                          <h3>Le journal d’une enseignante</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" xl="6" className="img">
                          <a className="imgLink" href="Ressource/C17" aria-label="Au fil des saisons : journal d'une enseignante" target="_blank" rel="noopener noreferrer">
                            <img className="d-block w-100" src="img/AtelierParent/2.5.jpg" alt="" />
                          </a>
                        </Col>
                        <Col xs="12" xl="6" className="text">
                        <div className="carreGris">
                          <p>Les moments clés d’une année scolaire.</p>
                        </div>
                        </Col>
                      </Row>
                    </div>

                    {/* **************** Slide 2.6 *************** */}
                    <div className="carousel-item">
                      <Row>
                        <Col className="titre">
                          <h3>La Boîte à outils</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" xl="6" className="img">
                          <a className="imgLink" href="https://communico.club/mod/standalone/content/22/index.php/contenu/lire/page/boite-a-outils.html" aria-label="Site externe Communico - Section Boîte à outils" target="_blank" rel="noopener noreferrer">
                            <img className="d-block w-100" src="img/AtelierParent/2.6.jpg" alt="" />
                          </a>
                        </Col>
                        <Col xs="12" xl="6" className="text">
                        <div className="carreGris">
                          <p>De précieux outils pour la salle de classe.</p>
                        </div>
                        </Col>
                      </Row>
                    </div>




                  </div>

                  <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-atelier-parents-30m.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                  <a className="carousel-control-prev" href="#carouselApproche" role="button" data-slide="prev" onClick={handleClick}>
                    <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                    <span className="sr-only">Précédent</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselApproche" role="button" data-slide="next" onClick={handleClick}>
                    <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                    <span className="sr-only">Suivant</span>
                  </a>
                  <button className="carousel-control-prev prevCarousel" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                    <span className="sr-only">Introduction</span>
                  </button>
                  <button className="carousel-control-next nextCarousel" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                    <span className="sr-only">Communico</span>
                  </button>
                </div>

              </div>
            </div>

            {/************* Communico *************/}

              <div id="collapseThree" className="card collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div className="card-body">

                  <div id="carouselCommunico" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                    <ol className="carousel-indicators" onClick={handleClick}>
                      <li data-target="#carouselCommunico" data-slide-to="0" className="active"></li>
                      <li data-target="#carouselCommunico" data-slide-to="1"></li>
                      <li data-target="#carouselCommunico" data-slide-to="2"></li>
                      <li data-target="#carouselCommunico" data-slide-to="3"></li>
                    </ol>
                    <div className="carousel-inner">

                      {/* **************** Slide 3.1 *************** */}
                      <div className="carousel-item active">
                        <Row>
                          <Col className="titre">
                          <h3>Le site <em>Approche culturelle de l’enseignement</em></h3><br/>
                          <a href="http://approcheculturelle.ca" target="_blank" rel="noopener noreferrer">http://approcheculturelle.ca</a>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="http://approcheculturelle.ca/index.php/auth/login" aria-label="Site externe Approche culturelle de l’enseignement - Page d'accueil" target="_blank" rel="noopener noreferrer">
                              <img className="d-block w-100" src="img/AtelierParent/3.1.jpg" alt="" />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                          <div className="carreGris">
                            <p><strong>L’importance de la langue et de la culture pour l’enseignement du français langue première en contexte minoritaire.</strong></p>
                          </div>

                          </Col>
                        </Row>
                      </div>

                      {/* **************** Slide 3.2 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                          <h3>S’approprier le contenu du site <em>Approche culturelle de l’enseignement</em></h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="http://approcheculturelle.ca/index.php/pageprincipale" aria-label="Site externe Approche culturelle de l’enseignement - Page principale" target="_blank" rel="noopener noreferrer">
                              <img className="d-block w-100" src="img/AtelierParent/3.2.jpg" alt="" />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                          <div className="carreGris">
                            <p>Les parents peuvent aussi suivre l’autoformation ou tout simplement fureter pour trouver de l’information et des ressources.</p>
                          </div>

                          </Col>
                        </Row>
                      </div>

                      {/* **************** Slide 3.3 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                          <h3>Quel est le rapport de votre enfant avec la langue française et la culture francophone?</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="Ressource/A32" aria-label="Synthèse des principes de l'approche culturelle de l'enseignement" target="_blank" rel="noopener noreferrer">
                              <img className="d-block w-100" src="img/AtelierParent/3.3.jpg" alt="" />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                          <div className="carreGris">
                            <p>Dix principes pour favoriser la langue et la culture à l’école de langue française.</p>
                          </div>

                          </Col>
                        </Row>
                      </div>

                      {/* **************** Slide 3.4 *************** */}
                      <div className="carousel-item">
                        <Row>
                          <Col className="titre">
                          <h3>La Banque pancanadienne de référents culturels</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" xl="6" className="img">
                            <a className="imgLink" href="https://referentsculturels.com" aria-label="Site externe Banque pancanadienne de référents culturels signifiants de la francophonie" target="_blank" rel="noopener noreferrer">
                              <img className="d-block w-100" src="img/AtelierParent/3.4.jpg" alt="" />
                            </a>
                          </Col>
                          <Col xs="12" xl="6" className="text">
                          <div className="carreGris">
                            <p>Cette ressource aide l’élève à mieux comprendre son environnement, à mieux s’y situer, à valoriser la langue française et à s’identifier à sa culture.</p>
                            <p><strong>Explorons la banque!</strong></p>
                          </div>

                          </Col>
                        </Row>
                      </div>




                    </div>

                    <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-atelier-parents-30m.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                    <a className="carousel-control-prev" href="#carouselCommunico" role="button" data-slide="prev" onClick={handleClick}>
                      <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                      <span className="sr-only">Précédent</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselCommunico" role="button" data-slide="next" onClick={handleClick}>
                      <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                      <span className="sr-only">Suivant</span>
                    </a>
                    <button className="carousel-control-prev prevCarousel" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                      <span className="sr-only">Approche culturelle de l’enseignement</span>
                    </button>
                    <button className="carousel-control-next nextCarousel" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                      <span className="sr-only">Appréciation</span>
                    </button>
                  </div>

                </div>
              </div>

              {/************* Appréciation *************/}

                <div id="collapseFour" className="card collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                  <div className="card-body">

                    <div id="carouselAppreciation" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                      <ol className="carousel-indicators" onClick={handleClick}>
                        <li data-target="#carouselAppreciation" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselAppreciation" data-slide-to="1"></li>
                      </ol>
                      <div className="carousel-inner">

                        {/* **************** Slide 4.1 *************** */}
                        <div className="carousel-item active">
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <img className="d-block w-100" src="img/AtelierParent/4.1.jpg" alt="" />
                            </Col>
                            <Col xs="12" xl="6" className="text">

                              <h3>Appréciation</h3>
                            <div className="carreGris">
                              <p>Votre opinion compte!</p>
                            </div>

                            </Col>
                          </Row>
                        </div>

                        {/* **************** Slide 4.2 *************** */}
                        <div className="carousel-item">
                          <Row>
                            <Col xs="12" xl="6" className="img">
                            <img className="d-block w-100" src="img/AtelierParent/4.2.jpg" alt="" />
                            </Col>
                            <Col xs="12" xl="6" className="text">

                              <h3>Merci!</h3>
                            <div className="carreGris">
                              <p>Cette ressource est produite avec le soutien financier des provinces et des territoires membres du Comité du Consortium du français langue première et du gouvernement du Canada. </p>
                            </div>

                            </Col>
                          </Row>
                        </div>


                      </div>

                      <a class="btn btn-primary-link ml-1" href="Ressources/Ateliers/cmec-consignes-atelier-parents-30m.pdf" target="_blank"><span class="far fa-file-pdf"></span> Consignes à l'animateur</a>

                      <a className="carousel-control-prev" href="#carouselAppreciation" role="button" data-slide="prev" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                        <span className="sr-only">Précédent</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselAppreciation" role="button" data-slide="next" onClick={handleClick}>
                        <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                        <span className="sr-only">Suivant</span>
                      </a>
                      <button className="carousel-control-prev prevCarousel" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                        <span className="sr-only">Communico</span>
                      </button>
                    </div>

                  </div>
                </div>

          </div>
        </main>
        <Footer />
      </>
    );
  }
}
export default AtelierParentsPage;
