import React from "react";
import {Component} from 'react'

import Header from "../Components/Header";
import Footer from "../Components/Footer";

class BesoinDeSuggestionsPage extends Component {
  render() {
    return (
      <>
        <Header />
        <main className="BesoinDeSuggestionsPage">
          <h1>À ne pas manquer</h1>
          <div className="inner-div pl-5">
            <p>Certaines ressources sont des incontournables qu’il faut absolument consulter</p>
            <ol type="a">
              <li>sur le site de l’approche culturelle de l’enseignement :
                <ul>
                  <li>la <em>Banque pancanadienne de référents culturels</em>,</li>
                  <li>les vidéos explicatifs des dix principes de l’approche culturelle de l’enseignement,</li>
                  <li>la <em>Banque de stratégies pédagogiques</em> de l’approche culturelle de l’enseignement</li>
                </ul>
              </li>
              <li>sur le site Communico :
                <ul>
                  <li>le Continuum langagier,</li>
                  <li>le journal d’une enseignante Au fil des saisons,</li>
                  <li>la Boîte à outils qui contient de nombreuses ressources</li>
                </ul>
              </li>
            </ol>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
export default BesoinDeSuggestionsPage;
