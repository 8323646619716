const SaviezVousData = [
    {
      "Id": 0,
    	"Image":"ELSA3-Fiche.png",
    	"Texte" : `<p>Le site <em>Communico</em> vous offre un outil de réflexion destiné aux parents sur l’utilisation du français en dehors de l’école. Plus les élèves auront l’occasion de vivre, en dehors des heures de classe, des expériences amusantes, positives et stimulantes en français, plus cette langue sera significative pour eux.</p>`,
      "Url" : "Ressources/SaviezVousQue/ELSA3-Fiche.pdf"
      /*"Url" : "https://communico.club/mod/standalone/content/22/index.php/contenu/lire/wp-content/uploads/sites/60/2018/03/ELSA3-Fiche.pdf"*/
    },
    {
      "Id": 1,
    	"Image":"C17.jpg",
    	"Texte" : `<p>L’on retrouve, sur le site <em>Communico</em>, le journal d’une enseignante, <em>Au fil des saisons</em>, qui relate le cheminement d’une nouvelle enseignante en contexte minoritaire francophone tout au long d’une année scolaire.</p>`,
      "Url" : "Ressource/C17"
      /*https://communico.club/mod/standalone/content/22/index.php/contenu/lire/wp-content/uploads/sites/60/2018/09/Au-fil-des-saisons.pdf#new_tab           C17*/
    },
    {
      "Id": 2,
    	"Image":"CLSA3_Scenarisation_TAB2-F.png",
    	"Texte" : `<p>Les spécialistes de la pédagogie à l’école de langue française (PELF) ont défini un canevas pour orienter la pédagogie propre à l’école de langue française en contexte minoritaire. Consultez-le sur le site <em>Communico</em>.</p>`,
      "Url" : "Ressources/SaviezVousQue/CLSA3_Scenarisation_TAB2-F.pdf"
      /*https://communico.club/mod/standalone/content/22/index.php/contenu/lire/wp-content/uploads/sites/60/2018/03/CLSA3_Scenarisation_TAB2-F.pdf*/
    },
    {
      "Id": 3,
    	"Image":"A31.jpg",
    	"Texte" : `<p>Le site <em>Approche culturelle de l’enseignement</em> contient une Banque de stratégies pédagogiques qui comprend des synopsis, des vidéos, des exemples de projets d’envergure et des suggestions d’apprentissages intégrés.</p>`,
      "Url" : "Ressource/A31"
      /*http://approcheculturelle.ca/index.php/affiche/contenu/cmec-11-1          A31*/
    },
    {
      "Id": 4,
    	"Image":"cmec-ss-1-1b.png",
    	"Texte" : `<p>Vous trouverez sur le site <em>Approche culturelle de l’enseignement</em> tout ce qu’il faut connaître sur la construction identitaire.</p>`,
      "Url" : "Ressource/A33"
      /*http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-1-1b*/
    },
    {
      "Id": 5,
    	"Image":"A28.jpg",
    	"Texte" : `<p>L’école de langue française ne peut ni évaluer ni imposer une identité aux élèves. Celle-ci peut cependant agir sur les conditions qui favorisent une construction identitaire francophone solide. Consultez le document portant sur fondements de l’approche culturelle de l’enseignement.</p>`,
      "Url" : "Ressource/A28"
      /*http://approcheculturelle.ca/assets/pdf/5456-CMEC-DocumentWeb.pdf         A28*/
    },
    {
      "Id": 6,
    	"Image":"facettes.png",
    	"Texte" : `<p>Au quotidien, le rôle du personnel enseignant dans l’acquisition des habiletés de communication orale chez les élèves comporte de multiples facettes? Les connaissez-vous.</p>`,
      "Url" : "Ressource/C23"
      /*https://communico.club/mod/standalone/content/22/index.php/contenu/lire/page/personnel-enseignant/role-enseignant/contenu/notions-theoriques/facettes.html*/
    }
  ]

export default SaviezVousData;
