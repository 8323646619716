export const RessourceData = [
  {
  UniqueKey : "C01",
  Provenance : "Communico",
  Titre : "Fondements et éléments d'un continuum langagier",
  Url : "/Ressources/C01/elem-contin-langagier.pdf",
  TypeDeRessource : "Guide pédagogique",
  Format : [0],
  Besoin : [1],
  TypeDeProfessionel : [1, 2, 5, 4, 3],
  Description : "Document théorique qui présente les fondements qui soutendent le développement du langage de l'enfant en fonction des groupes d'âge (préscolaire jusqu'au secondaire)",
  Date: "2008",
  Auteur: "Conseil des ministres de l’Éducation, Canada",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C02",
  Provenance : "Communico",
  Titre : "Le continuum langagier",
  Url : "/Ressources/C02/ELSA1_continuum-langagier_ep6f.pdf",
  TypeDeRessource : "Grille de référence",
  Format : [2],
  Besoin : [1],
  TypeDeProfessionel : [1, 2, 5, 4, 3],
  Description : "Le tableau présente les compétences langagières en fonction des groupes d'âge : du préscolaire jusqu'au secondaire",
  Date: "2008",
  Auteur: "Conseil des ministres de l’Éducation, Canada",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C03",
  Provenance : "Communico",
  Titre : "Les besoins psychopédagogiques de l’élève",
  Url : "/Ressources/C03/ELSA2-Portrait_ep.pdf",
  TypeDeRessource : "Fiche à remplir",
  Format : [0],
  Besoin : [1, 2, 3],
  TypeDeProfessionel : [1, 2, 3],
  Description : "Le tableau présente les besoins psychopédagogiques de l’élève et suggère quelques pratiques pour y répondre pédagogiquement.",
  Date: "Les compétences langagières, la construction identitaire, l'approche culturelle de l'enseignement",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C04",
  Provenance : "Communico",
  Titre : "Conditions, concepts et principes qui favorisent la communication orale",
  Url : "https://Communico.club/mod/standalone/content/22/index.php/contenu/lire/page/eleves/favoriser-comm-orale/contenu/notions-theoriques/principes-fav-comm-orale.html",
  TypeDeRessource : "Notions théoriques",
  Format : [3],
  Besoin : [1, 2],
  TypeDeProfessionel : [1, 2, 3, 5],
  Description : "Présentation des concepts qui favorisent la construction identitaire francophone",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C05",
  Provenance : "Communico",
  Titre : "Outil de découverte du milieu de vie de l'élève",
  Url : "/Ressources/C05/ELSA4_Outil.pdf",
  TypeDeRessource : "Fiche à remplir",
  Format : [0],
  Besoin : [1, 4],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Outil qui permet de faire un bilan de la situation familiale de l'élève",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C06",
  Provenance : "Communico",
  Titre : "Les ressources culturelles et médiatiques francophones",
  Url : "/Ressources/C06/Exploiter-ress-cult-media.pdf",
  TypeDeRessource : "Liste de ressources, Pistes d'exploration",
  Format : [2],
  Besoin : [2, 4],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Guide qui présente des ressources francophones ainsi que des stratégiqes pédagogiques pour utilisation en classe avec les élèves",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C07",
  Provenance : "Communico",
  Titre : "Appréciation des compétences langagières des élèves parlant peu ou ne parlant pas français (francisation)",
  Url : "/Ressources/C07/PESA5-outil_appreciation3.pdf",
  TypeDeRessource : "Outil d'appréciation, Outil d'observation",
  Format : [0],
  Besoin : [4],
  TypeDeProfessionel : [1, 3, 2],
  Description : "L’Outil d’appréciation des compétences langagières des élèves parlant peu ou ne parlant pas français (francisation) propose des descripteurs adaptés aux phases de développement des compétences discursives, linguistiques et communicatives des élèves ne parlant pas le français ou le parlant peu. ",
  Date: "2017",
  Auteur: "Division scolaire franco-manitobaine ",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C08",
  Provenance : "Communico",
  Titre : "Outil d’appréciation de l’oral spontané chez les élèves de la maternelle à la 12e dans divers contextes",
  Url : "/Ressources/C08/PESA5-outil_appreciation2.pdf",
  TypeDeRessource : "Outil d'appréciation",
  Format : [0],
  Besoin : [1],
  TypeDeProfessionel : [1, 3, 2],
  Description : "L'outil d'appréciation permet à l'enseignant de faire des constats au niveau de la communication orale de l'élève dans divers contextes.",
  Date: "2017",
  Auteur: "Division scolaire franco-manitobaine ",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C09",
  Provenance : "Communico",
  Titre : "Les interventions langagières stratégiques",
  Url : "/Ressources/C09/PESA7-TAB1_interventions-stategiques-FPR.pdf",
  TypeDeRessource : "Guide pédagogique",
  Format : [0],
  Besoin : [1],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Ce guide aide l'enseignant à orienter ses interventions pour favoriser la progression graduelle de l’usage du français par les élèves qui apprennent la langue. Ces interventions visent à encourager les élèves, à leur proposer des modèles, à leur donner des moyens pour s’exprimer en français.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C10",
  Provenance : "Communico",
  Titre : "Conditions, concepts et principes qui favorisent la communication orale",
  Url : "https://Communico.club/mod/standalone/content/22/index.php/contenu/lire/page/classe/favoriser-comm-orale/contenu/notions-theoriques/conditions.html",
  TypeDeRessource : "Notions théoriques, Exemples",
  Format : [3],
  Besoin : [1, 2, 3],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Le visuel présente les conditions, concepts et principes qui favorisent la communication orale ainsi que des exemples d'applications en classe.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C11",
  Provenance : "Communico",
  Titre : "Les cinq pôles de l’intervention pédagogique en contexte minoritaire",
  Url : "https://Communico.club/mod/standalone/content/22/index.php/contenu/lire/page/classe/scenario-ped/contenu/notions-theoriques/poles.html",
  TypeDeRessource : "Notions théoriques",
  Format : [3],
  Besoin : [3, 6],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Le visuel présente cinq pôles : les référents culturels, des situations d'apprentissage contextualisées et signifiantes, un climat scolaire positif, une ouverture à la diversité et à la francophonie et l'importance des interactions sociales",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C12",
  Provenance : "Communico",
  Titre : "École de langue française et école anglophone avec un programme d’immersion : deux raisons d'être",
  Url : "https://Communico.club/mod/standalone/content/22/index.php/contenu/lire/page/ecole/mandat-ecole/contenu/notions-theoriques/ecole.html",
  TypeDeRessource : "Notions théoriques",
  Format : [2],
  Besoin : [5],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Ce guide présente les raisons d'être, les profils des élèves et des parents, les fondements juridiques, les milieux d'apprentissage de l'école de langue française et de l'école anglophone ayant un programme d'immersion.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C13",
  Provenance : "Communico",
  Titre : "Suggestions de contenu pour la trousse d’information des parents d'élèves qui s'inscrivent à l'école de langue française",
  Url : "/Ressources/C13/ESA3-4FavoriserCO-trousse.pdf",
  TypeDeRessource : "Fiche d'information",
  Format : [0],
  Besoin : [5, 4],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Fiche d'information contenant des suggestions pour le contenu d'une trousse d'information pour l'accueil de l'élève et de ses parents à l'école de langue française",
  Date: "2018",
  Auteur: "sOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C14",
  Provenance : "Communico",
  Titre : "Concertation entre l’école et les groupes communautaires",
  Url : "/Ressources/C14/Notions-theoriques_Concertation.pdf",
  TypeDeRessource : "Notions théoriques, Exemples",
  Format : [2],
  Besoin : [5, 4],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Ce guide d'information aborde la relation école -communauté dans un concept de francisation et offre des suggestions d'activités, de ressources et de services des groupes communautaires",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C15",
  Provenance : "Communico",
  Titre : "Une activité école-famille-communauté",
  Url : "/Ressources/C15/EFCSA3-Aide-memoire-planification-BAOep2F.pdf",
  TypeDeRessource : "Aide-mémoire",
  Format : [0],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Pour implanter un projet qui implique la participation de l’école, de la famille et de la communauté, il est souhaitable de définir les ressources humaines, matérielles et financières nécessaires à sa réalisation. Cet aide-mémoire accompagne le personnel enseignant dans sa planification",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C16",
  Provenance : "Communico",
  Titre : "Outil de découverte de la vie francophone dans votre communauté",
  Url : "/Ressources/C16/EFCSA1_Outil-decouverte-communaute_ep2F.pdf",
  TypeDeRessource : "Outil d'observation",
  Format : [0],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Outil qui permet de faire un bilan des activités en français dans la communauté",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C17",
  Provenance : "Communico",
  Titre : "Au fil des saisons : journal d'une enseignante",
  Url : "/Ressources/C17/Au-fil-des-saisons.pdf",
  TypeDeRessource : "Outil d'appréciation",
  Format : [0],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Au fil des saisons : journal d’une enseignante, relate le cheminement d’une nouvelle enseignante en contexte minoritaire francophone, tout au long d'une année scolaire.",
  Date: "2018",
  Auteur: "Conseil des ministres de l’Éducation, Canada",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C18",
  Provenance : "Communico",
  Titre : "Boîte à outils Communico",
  Url : "https://Communico.club/mod/standalone/content/22/index.php/contenu/lire/page/boite-a-outils.html",
  TypeDeRessource : "Ressource pédagogique",
  Format : [1],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "La boîte à outils propose une série de ressources pédagogiques pour appuyer le personnel scolaire qui oeuvre en contexte minoritaire et en francisation.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C19",
  Provenance : "Communico",
  Titre : "L'élève qui fréquente une école francophone en contexte minoritaire",
  Url : "/Ressources/C19/index.html",
  TypeDeRessource : "Notions théoriques",
  Format : [1],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Cette vidéo fait un survol du portrait de l'élève qui fréquente une école de langue française en contexte minoritaire.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C20",
  Provenance : "Communico",
  Titre : "L'enseignement de l'oral",
  Url : "/Ressources/C20/index.html",
  TypeDeRessource : "Notions théoriques",
  Format : [1],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Cette vidéo fait un survol de l'enseignement de l'oral.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C21",
  Provenance : "Communico",
  Titre : "La classe, milieu d'apprentissage de la langue française",
  Url : "/Ressources/C21/index.html",
  TypeDeRessource : "Notions théoriques",
  Format : [1],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Cette vidéo fait un survol des opportunités d'enseignement et d'apprentissage de la langue française que présente l'environnement de la classe.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C22",
  Provenance : "Communico",
  Titre : "L'école de langue française en contexte minoritaire",
  Url : "/Ressources/C22/index.html",
  TypeDeRessource : "Notions théoriques",
  Format : [1],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Cette vidéo présente l'école de langue française en milieu minoritaire, son mandat, sa vitalité et aborde aussi le contexte de l'école anglophone ayant un programme d'immersion.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C23",
  Provenance : "Communico",
  Titre : "Les multiples facettes du rôle du personnel enseignant dans l'enseignement de l'oral",
  Url : "/Ressources/C23/PESA3-Roles-facettes_BAO.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [0],
  Besoin : [1],
  TypeDeProfessionel : [1, 3, 2],
  Description : "Le point de vue de chercheurs en matière d'enseignement de l'oral.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C24",
  Provenance : "Communico",
  Titre : "Les étapes d’un atelier formatif",
  Url : "/Ressources/C24/PESA3-SCHEMA4.pdf",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques",
  Format : [0],
  Besoin : [1],
  TypeDeProfessionel : [1, 3],
  Description : "Tableau qui présente les étapes d'un atelier formatif en fonction de l'acquisition de l'oral.",
  Date: "2016 ou 2018",
  Auteur: "SOFAD Adapté de edusco.education.fr/ressources-2016 - Ministère de l’Éducation nationale, de l’Enseignement supérieur et de la Recherche, France, 2016.",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C25",
  Provenance : "Communico",
  Titre : "Quelques techniques d'animation",
  Url : "/Ressources/C25/PESA3-SCHEMA2.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [0],
  Besoin : [1, 4],
  TypeDeProfessionel : [1, 3],
  Description : "Figure qui présente quelques techniques d'animation",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C26",
  Provenance : "Communico",
  Titre : "Les types d'erreurs",
  Url : "/Ressources/C26/PESA7-Types-erreurs_BAO.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [0],
  Besoin : [1, 4],
  TypeDeProfessionel : [1, 3, 4],
  Description : "Fiche qui présente les types d'erreurs associés à l'oral",
  Date: "2018",
  Auteur: " SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "C27",
  Provenance : "Communico",
  Titre : "École-famille-communauté",
  Url : "/Ressources/C27/index.html",
  TypeDeRessource : "Notions théoriques",
  Format : [1],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Cette vidéo aborde l'importance que revêt le partenariat école-famille-communauté dans l'enseignement et l'apprentissage en contexte minoritaire.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A28",
  Provenance : "Culturelle",
  Titre : "Document de fondement pour une approche culturelle de l'enseignement",
  Url : "/Ressources/A28/5456-CMEC-DocumentWeb.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [0],
  Besoin : [5, 4, 3, 2, 1],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Le document de fondement présente l'appreoche culturelle de l'enseignemnt en contexte francophone minoritaire.",
  Date: "2012",
  Auteur: "Conseil des ministres de l’Éducation, Canada",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A29",
  Provenance : "Culturelle",
  Titre : "Le Cadre d’appropriation de la culture dans les écoles de langue française",
  Url : "/Ressources/A29/cadre_appropriation.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [2],
  Besoin : [5, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 4, 5],
  Description : "Présentation des composantes du cadre d'appropriation de la culture dans les écoles de langue française.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A30",
  Provenance : "Culturelle",
  Titre : "La Banque pancanadienne des référents culturels ",
  Url : "https://referentsculturels.com/",
  TypeDeRessource : "Banque de ressources",
  Format : [4],
  Besoin : [5, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "La Banque pancanadienne des référents culturels signifiants pour la francophonie a pour mandat de diffuser les éléments culturels qui contribuent d’une manière significative à la construction de l’identité culturelle des élèves et à la vitalité de la communauté francophone du Canada.",
  Date: "",
  Auteur: "Conseil des ministres de l’Éducation, Canada",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A31",
  Provenance : "Culturelle",
  Titre : "Banque de stratégies pédagogiques sur l'approche culturelle de l'enseignement",
  Url : "/Ressources/A31/index.html",
  TypeDeRessource : "Banque de vidéos",
  Format : [1],
  Besoin : [5, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5],
  Description : "La Banque de stratégies présente une série de capsules vidéos sur les pratiques pédagogiques qui a pour but d'illustrer différentes activités d’apprentissage intégrant une approche culturelle de l’enseignement et ce, dans différentes matières.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A32",
  Provenance : "Culturelle",
  Titre : "Synthèse des principes de l'approche culturelle de l'enseignement",
  Url : "/Ressources/A32/index.html",
  TypeDeRessource : "Notions théoriques",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Cette synthèse comprend dix vidéos qui présentent chacun des principes de l'approche culturelle de l'enseignement.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A33",
  Provenance : "Culturelle",
  Titre : "La construction identitaire",
  Url : "/Ressources/A33/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du premier principe de l'approche culturelle de l'enseignement : la construction identitaire",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A34",
  Provenance : "Culturelle",
  Titre : "Programmes d'études",
  Url : "/Ressources/A34/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du deuxième principe de l'approche culturelle de l'enseignement : les programmes d'études",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A35",
  Provenance : "Culturelle",
  Titre : "Appropriation de la culture",
  Url : "/Ressources/A35/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du troisième principe de l'approche culturelle de l'enseignement : appropriation de la culture",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A36",
  Provenance : "Culturelle",
  Titre : "Langue et culture",
  Url : "/Ressources/A36/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du quatrième principe de l'approche culturelle de l'enseignement : langue et culture",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A37",
  Provenance : "Culturelle",
  Titre : "Dialogue authentique",
  Url : "/Ressources/A37/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du cinquième principe de l'approche culturelle de l'enseignement : dialogue authentique",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A38",
  Provenance : "Culturelle",
  Titre : "Héritage culturel",
  Url : "/Ressources/A38/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du sixième principe de l'approche culturelle de l'enseignement : héritage culturel",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A39",
  Provenance : "Culturelle",
  Titre : "Leadership culturel",
  Url : "/Ressources/A39/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du septième principe de l'approche culturelle de l'enseignement : leadership culturel",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A40",
  Provenance : "Culturelle",
  Titre : "Engagement culturel",
  Url : "/Ressources/A40/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du huitième principe de l'approche culturelle de l'enseignement : engagement culturel",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A41",
  Provenance : "Culturelle",
  Titre : "Diversité culturelle",
  Url : "/Ressources/A41/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du neuvième principe de l'approche culturelle de l'enseignement : diversité culturelle",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A42",
  Provenance : "Culturelle",
  Titre : "Famille, école, communauté",
  Url : "/Ressources/A42/index.html",
  TypeDeRessource : "Notions théoriques, Stratégies pédagogiques, Activités d'apprentissage, Témoignages",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Présentation du dixième principe de l'approche culturelle de l'enseignement : école, famille, communauté",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A43",
  Provenance : "Culturelle",
  Titre : "La Boîte à outils de l'Approche culturelle de l'enseignement",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-bao-1",
  TypeDeRessource : "Outil d'appréciation",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "La Boîte à outils de l'Approche culturelle de l'enseignement contient plusieurs ressources pouvant appuyer l'enseignement et l'apprentissage en contexte minoritaire ",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "A44",
  Provenance : "Culturelle",
  Titre : "Démarche de sélection de référents culturels signifiants pour la francophonie",
  Url : "/Ressources/A44/cmec-14-3-1_01.gif",
  TypeDeRessource : "Notions théoriques",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "La démarche est présentée en trois étapes : qu'est-ce qu'un référent culturel, quelle est sa valeur pédagogique, quelle est sa contribution à la formation globale de l'élève.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A45",
  Provenance : "Culturelle",
  Titre : "Processus dynamique d’appropriation de la culture francophone",
  Url : "/Ressources/A45/cmec-14-2-1_01.gif",
  TypeDeRessource : "Notions théoriques",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "La figure présente le processus dynamique d’appropriation de la culture francophone en illustrant, au moyen de verbes, les habiletés liées aux trois modes d’appropriation selon les volets culturel, interculturel et transculturel.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A46",
  Provenance : "Culturelle",
  Titre : "Exemples de comportements observables illustrant le processus dynamique d’appropriation de la culture francophone",
  Url : "/Ressources/A46/cmec-14-1-2_01.gif",
  TypeDeRessource : "Exemples",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Les exemples du tableau illustrent chaque étape du processus dynamique d’appropriation de la culture francophone.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A47",
  Provenance : "Culturelle",
  Titre : "Exemples de stratégies de transmission et d’appropriation de la culture francophone",
  Url : "/Ressources/A47/cmec-14-1-3_01.gif",
  TypeDeRessource : "Exemples",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Les exemples du tableau illustrent des stratégies de transmission et d'appropriation en fonction de chaque étape du processus dynamique d’appropriation de la culture francophone.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A48",
  Provenance : "Culturelle",
  Titre : "Pistes de visionnement des vidéos des dix principes de l'approche culturelle de l'enseignement",
  Url : "/Ressources/A48/index.html",
  TypeDeRessource : "Ressource pédagogique",
  Format : [0],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Une série de fiches présentant des pistes de visionnement des 10 vidéos des principes de l'approche culturelle de l'enseignement.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A49",
  Provenance : "Culturelle",
  Titre : "Plus de 400 ans d'Amérique française",
  Url : "/Ressources/A49/index.html",
  TypeDeRessource : "Trame historique",
  Format : [1],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Une trame historique des 400 ans de vie française en Amérique",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A50",
  Provenance : "Culturelle",
  Titre : "Réalités sociales",
  Url : "/Ressources/A50/realites_sociales.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "L’assimilation linguistique et culturelle, L’exogamie, La revitalisation de la communauté francophone par l’immigration",
  Date: "2013/2006",
  Auteur: "",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A51",
  Provenance : "Culturelle",
  Titre : "J'établis le portrait de la francophonie des élèves de ma classe",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-4-4c",
  TypeDeRessource : "Fiche à remplir",
  Format : [3],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1],
  Description : "Outil qui facilite la découverte des élèves et de leur famille",
  Date: "",
  Auteur: "",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "A52",
  Provenance : "Culturelle",
  Titre : "Quand le passé a un impact sur aujourd'hui et demain!",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-6-4c",
  TypeDeRessource : "Activités d'apprentissage",
  Format : [3],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1],
  Description : "Outil interactif qui permet de découvrir les événements de l’histoire de l’Amérique française qui ont ou ont eu une incidence sur l’héritage culturel de sa communauté ?",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "A53",
  Provenance : "Culturelle",
  Titre : "Mon bottin de ressources culturelles francophones",
  Url : "/Ressources/A53/monBottin.pdf",
  TypeDeRessource : "Ressource pédagogique",
  Format : [0],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1],
  Description : "À l’aide d'un questionnaire, je réfléchis aux personnes qui sont des modèles culturels autour de moi et je me construis un bottin de ressources culturelles de ma communauté.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A54",
  Provenance : "Culturelle",
  Titre : "L’éducation des élèves doués et le développement des talents",
  Url : "/Ressources/A54/education-des-elves-doues-at-developpement-des-talents.pdf",
  TypeDeRessource : "Guide pédagogique, Guide administratif",
  Format : [0],
  Besoin : [5],
  TypeDeProfessionel : [1, 2, 3],
  Description : " Ressource conçue en vue de fournir aux éducateurs la structure, les documents et les outils sur lesquels ils pourront s’appuyer pour offrir une gamme complète de programmes et de services appropriés aux élèves ayant des dons ou des talents particuliers et ce, de la maternelle à la 12e année.",
  Date: "2011",
  Auteur: "© Droit d’auteur de la Couronne, Province de la Nouvelle-Écosse, 2011",
  Tumbnails : "",
  LoginNeeded : false
  },



  {
  UniqueKey : "A55",
  Provenance : "Culturelle",
  Titre : "Ma courtepointe culturelle",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-9-4",
  TypeDeRessource : "Ressource pédagogique",
  Format : [2],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 3],
  Description : "L’acquisition, sur le plan personnel et professionnel, d’une sensibilité interculturelle est un processus continuel. La courtepointe culturelle d'Isabelle contient ses expériences liés à son parcours personnel et professionnel selon les volets culturels, interculturel et transculturel.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "A56",
  Provenance : "Culturelle",
  Titre : "Regard critique sur l'intégration d'éléments culturels dans les programmes d'études et autres documents de référence",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-2-14",
  TypeDeRessource : "Outil d'analyse",
  Format : [3],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "Outil interactif qui donne accès aux  documents ministériels des régions du Canada afin de voir si la culture y est intégrée et si non offrir des pistes pour l'intégrer.",
  Date: "2013",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "A57",
  Provenance : "Culturelle",
  Titre : "L’authenticité du dialogue dans les réseaux sociaux",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-5-4b",
  TypeDeRessource : "Activités d'apprentissage",
  Format : [3],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1],
  Description : "Fiche d'activité qui présente une variété de médias sociaux auquels il est possible d'associer des exemples.",
  Date: "2013",
  Auteur: " SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "A58",
  Provenance : "Culturelle",
  Titre : "Mes pratiques gagnantes",
  Url : "http://approcheculturelle.ca/index.php/affiche/contenu/cmec-ss-4-4a",
  TypeDeRessource : "Activités d'apprentissage",
  Format : [3],
  Besoin : [5, 4, 1, 3, 2],
  TypeDeProfessionel : [1],
  Description : "Exemples de pratiques gagnantes pour développer une meilleure relation enseignant-élèves.",
  Date: "2013",
  Auteur: "SOFAD - Adapté de : Je m'engage, tu t'engages - Guide de réflexion sur la relation enseignant-élèves. CFORP, 2008.",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C59",
  Provenance : "Communico",
  Titre : "Médiagraphie",
  Url : "https://Communico.club/mod/standalone/content/22/index.php/contenu/lire/page/boite-a-outils/mediagraphie.html",
  TypeDeRessource : "Bibliographie, webographie",
  Format : [2],
  Besoin : [1, 4],
  TypeDeProfessionel : [1, 2, 3, 5, 6, 7],
  Description : "La médiagraphie propose une bibliographie, des vidéos, des webographie utiles dans l'enseignement de l'oral.",
  Date: "2018",
  Auteur: "SOFAD",
  Tumbnails : "",
  LoginNeeded : true
  },



  {
  UniqueKey : "C60",
  Provenance : "Communico",
  Titre : "La dimension identitaire d'une première langue",
  Url : "/Ressources/C60/PESA3-Pour-en-savoir-plus.pdf",
  TypeDeRessource : "Notions théoriques",
  Format : [0],
  Besoin : [1, 4],
  TypeDeProfessionel : [1, 2, 3, 4],
  Description : "Feuillet informatif qui  présente comment aborder l'évaluation d'une langue parlée",
  Date: "2018",
  Auteur: "SOFAD - référence à une bibliographie.",
  Tumbnails : "",
  LoginNeeded : false
  },

]

export const FormatData = [
  {
    Id: 0,
    Name: "PDF"
  },
  {
    Id: 1,
    Name: "Vidéo"
  },
  {
    Id: 2,
    Name: "HTML (texte/image)"
  },
  {
    Id: 3,
    Name: "HTML (interactif)"
  },
  {
    Id: 4,
    Name: "HTML (application)"
  },

]

export const TypeDeProfessionelData =[
  {
    Id: 0,
    Name: "Tous"
  },
  {
    Id: 1,
    Name: "Personnel enseignant"
  },
  {
    Id: 2,
    Name: "Cadre scolaire"
  },
  {
    Id: 3,
    Name: "Conseiller pédagogique"
  },
  {
    Id: 4,
    Name: "Parent"
  },
  {
    Id: 5,
    Name: "Agent ministériel"
  },
  {
    Id: 6,
    Name: "Concepteur des programmes d’études"
  },
  {
    Id: 7,
    Name: "Responsable de l’animation culturelle"
  }
]


export const BesoinData =[
  {
    Id: 0,
    Name: "Tous"
  },
  {
    Id: 1,
    Name: "Les compétences langagières"
  },
  {
    Id: 2,
    Name: "La construction identitaire"
  },
  {
    Id: 3,
    Name: "L'approche culturelle de l'enseignement"
  },
  {
    Id: 4,
    Name: "La francisation"
  },
  {
    Id: 5,
    Name: "Français langue première"
  },
  {
    Id: 6,
    Name: "Les référents culturels"


  },
]
