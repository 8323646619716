import React from "react";
import {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";

import {updateProfessionnel, updateBesoin} from '../actions/FilterActions'

import {Row, Col, Button} from 'react-bootstrap';

class Fliter extends Component {

  constructor(props) {
    super(props);
    this.state = {
         TypeDeProfessionel: this.props.Filter.TypeDeProfessionel,
         Besoin: this.props.Filter.Besoin
    }
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler(){
    this.props.onUpdateProfessionnel(this.state.TypeDeProfessionel)
    this.props.onUpdateBesoin(this.state.Besoin)
    if (this.props.history.location.pathname !== '/Recherche'){
      this.props.history.push('/Recherche')
    }
  }

  onChangeHandler(){
    this.setState({TypeDeProfessionel: parseInt(document.getElementById('TypeDeProfessionelSelect').value)});
    this.setState({Besoin: parseInt(document.getElementById('BesoinSelect').value)});

    this.props.onUpdateProfessionnel(parseInt(document.getElementById('TypeDeProfessionelSelect').value))
    this.props.onUpdateBesoin(parseInt(document.getElementById('BesoinSelect').value))
  }


  CreateOption(){
    console.log(this.props.Filter.TypeDeProfessionel)
    return this.props.TypeDeProfessionel.map((Type) => {
      return (
        <option key={Type.Id} value={Type.Id}>{Type.Name}</option>
      )
    });
  }

  CreateDropdownBesoin(){
    return this.props.Besoin.map((Besoin) => {
      return (
        <option key={Besoin.Id} value={Besoin.Id}>{Besoin.Name}</option>
      )
    });
  }


  render() {
    return (
      <Row className="FilterComponent">
        <Col xs="1" className="d-none d-lg-flex searchIcon">
          <span className="fa fa-search" aria-hidden="true"></span>
        </Col>
        <Col xs="12" lg="9">
          <Row>
            <Col xs="12" xl="6">
              <label htmlFor="TypeDeProfessionelSelect">Quel type de professionnel êtes-vous?</label>
            </Col>
            <Col xs="12" xl="6">
              <select id="TypeDeProfessionelSelect" className="form-control" value={this.state.TypeDeProfessionel} onChange={this.onChangeHandler}>
                {this.CreateOption()}
              </select>
            </Col>
            <Col xs="12" xl="6">
              <label htmlFor="BesoinSelect">Quels sont vos besoins?</label>
            </Col>
            <Col xs="12" xl="6">
              <select id="BesoinSelect" className="form-control" value={this.state.Besoin} onChange={this.onChangeHandler}>
                {this.CreateDropdownBesoin()}
              </select>

                {this.props.showSaviezVousBtn === true ? <a href="#LeSaviezVous" className="float-right">Le saviez-vous?</a> : null}
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="2" className="goBtn">
          <Button onClick={this.onClickHandler} >ALLEZ&#x2011;Y!</Button>
        </Col>
        <Col xs="12">
        </Col>
      </Row>
    );
  }
}



function mapStateToProps(state, ownProps){
    return{
      Filter : state.FilterReducer,
      TypeDeProfessionel : state.TypeDeProfessionelReducer,
      Besoin : state.BesoinReducer,
    };
}


const mapActionsToProps = {
    onUpdateProfessionnel : updateProfessionnel,
    onUpdateBesoin : updateBesoin
}


export default connect(mapStateToProps, mapActionsToProps)(Fliter);
