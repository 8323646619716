import React from "react";
import {Component} from 'react'
import {connect} from 'react-redux'
import $ from "jquery"

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Filter from "../Components/FilterComponent"
import ModalComponent from "../Components/ModalComponent";
import AblePlayerComponent from "../Components/AblePlayerComponent"

import {Row, Col, Card} from 'react-bootstrap';


class HomePage extends Component {

  componentDidMount() {
      document.addEventListener('scroll', this.handleScroll, {capture: true,passive: true})


      $('a[href^="#"]').click(function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
      });

      /*document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
        });
      });*/


  }

  componentWillUnmount() {
      document.removeEventListener('scroll', this.handleScroll, {capture: true,passive: true})
  }

  handleScroll(e) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop
      const HalfclientHeight = document.documentElement.clientHeight / 2
      const scrollUpArrayPostion = document.getElementById("scrollUp").offsetTop;
      const BreakingPoint = scrollUpArrayPostion - HalfclientHeight;

      if (winScroll < BreakingPoint){
        document.getElementById("scrollDown").classList.remove("hide");
        document.getElementById("scrollUp").classList.add("hide");
      }else{
        document.getElementById("scrollDown").classList.add("hide");
        document.getElementById("scrollUp").classList.remove("hide");
      }
  }

  scrollDown() {
      var y = document.getElementById("scrollUp").offsetTop;
      window.scroll({top: y, left: 0, behavior: 'smooth' })
  }

  scrollUp(){
      window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }

  render() {
     function handleClick(e){
        var $this = $(e.target).parents(".carousel");
        setTimeout(function(){
          if ($this.find('.carousel-item:first-child').hasClass('active')) {
              $this.children('.carousel-control-prev').hide();
              $this.children('.carousel-control-next').show();
          } else if ($this.find('.carousel-item:last-child').hasClass('active')) {
              $this.children('.carousel-control-prev').show();
              $this.children('.carousel-control-next').hide();
          } else {
              $this.children('.carousel-control-prev').show();
              $this.children('.carousel-control-next').show();
          }
        }, 620, $this);
      }



var vidDesc =`
  <p><strong>Aide à la navigation :</strong> La vidéo présente en alternance les propos d’un narrateur et les éléments visuels de la vidéo qui sont porteurs de contenu.</p>
  <p><strong>Narrateur</strong></p>
  <p>Vous enseignez en français dans des écoles où le français est la langue première, mais dans un contexte minoritaire? Vous cherchez des ressources qui sont adaptées à votre réalité? Ceci est pour vous.</p>
  <p>Saviez-vous qu’il existe au Canada un Conseil des ministres de l’Éducation (CMEC) et un Comité du Consortium du français langue première qui réalise, avec l’appui du gouvernement du Canada, des ressources éducatives qui tiennent compte des particularités de l’apprentissage et de l’enseignement du français en contexte minoritaire au Canada?</p>
  <p><strong>Description visuelle</strong></p>
  <p>Des scènes se succèdent à l’écran : de jeunes élèves et un enseignant regroupés autour d’une table où repose une maquette; une enseignante et quatre jeunes élèves en train de faire du jardinage en salle de classe; une enseignante qui parle à deux adolescents dans une bibliothèque; un enseignant qui tape dans la main d’un jeune élève assis devant un ordinateur; une enseignante qui lit un livre à un groupe de jeunes élèves en salle de classe; et un enseignant et ses élèves portant tous des lunettes de protection regroupés autour d’une table de laboratoire. Les mots « français langue première », « dans un contexte minoritaire » et « ressources adaptées à votre réalité » apparaissent à l’écran en même temps que les prononce le narrateur. Au haut de l’écran apparaît le logo du CMEC avec le nom Conseil des ministères de l’Éducation (Canada), et au bas de l’écran, le nom Comité du Consortium du français langue première. La scène change pour montrer une femme de dos devant un ordinateur portable qui écrit les mots suivants dans une barre de recherche : Ressources + FLP + contexte minoritaire. Elle clique sur « Rechercher » et le premier lien qui apparaît est le site Web de <em>J’enseigne en français au Canada</em>.</p>
  <p><strong>Narrateur</strong></p>
  <p><em>J’enseigne en français au Canada</em> est votre porte d’entrée pour accéder à ces ressources.</p>
  <p>Vous connaissez peut-être déjà l’autoformation <em>Communico</em>, portant sur la communication orale, ou l’autoformation <em>Approche culturelle de l’enseignement</em>.</p>
  <p>Inscrivez-vous dès maintenant!</p>
  <p>Un outil de recherche simple qui vous permet de consulter directement une soixantaine de documents et d’activités tirés de <em>Communico</em>, de l’<em>Approche culturelle de l’enseignement</em> et éventuellement des futurs projets du Consortium conçus pour vous.</p>
  <p><strong>Description visuelle</strong></p>
  <p>Le logo de <em>J’enseigne en français au Canada</em> apparaît à l’écran, puis une page du site Web s’affiche. Sur le côté gauche de la page se trouve une vidéo sous le titre « Découvrez les ressources ». Sur le côté droit se trouvent des menus pour faire une recherche. Sous ces menus apparaissent deux autoformations, soit <em>Communico</em> et <em>Approche culturelle de l’enseignement</em>. La souris à l’écran clique sur le premier menu contextuel où l’on demande « Quel type de professionnel êtes-vous? ». Parmi les choix du menu, la souris sélectionne « Personnel enseignant ». La souris clique ensuite sur le deuxième menu contextuel où l’on demande « Quels sont vos besoins? » et sélectionne « Français langue première » parmi les choix. La souris clique sur le bouton « Allez-y! » pour lancer la recherche. Les résultats qui apparaissent se rapportent tous à l’autoformation <em>Communico</em>.</p>
  <p><strong>Narrateur</strong></p>
  <p>Ajoutez <em>J’enseigne en français au Canada</em> à vos favoris. Faites-en part à vos collègues!</p>
  <p><strong>Description visuelle</strong></p>
  <p>À l’écran se trouve le titre « Favori ajouté » au-dessus d’une barre de recherche dans laquelle est tapée l’adresse jenseigne-en-français.ca. La souris clique sur l’étoile à côté du site pour l’ajouter aux favoris. L’écran change pour montrer le titre « Partagez! » Au-dessus des logos de Facebook, Twitter, YouTube et LinkedIn. À l’écran sont tapés les mots « Une réalisation du Comité du Consortium du français langue première (CCFLP), 2019 ».</p>
`

    return (
      <>
        <a id="top"></a>
        <Header/>


          <div className="homePage">
            <Row>
              <Col xs="12" md="6" xl="5">
                <Row>
                  <Col xs="12" lg="10">
                  <p className="introText py-3 inner-div">Ce site est votre porte d'entrée pour découvrir et utiliser les ressources gratuites et de grande qualité liées à l'apprentissage du français langue première en milieu minoritaire, au Canada.</p>
                    <h1 className="py-3 inner-div">DÉCOUVREZ LES RESSOURCES</h1>
                        <AblePlayerComponent src="https://player.vimeo.com/external/395966447.sd.mp4?s=2ed3710ff683720162232d29ebda42417a1486ac&profile_id=165" captionSrc="Ressources/Ateliers/Video-promo-sous-titrage.txt" videoDescription={vidDesc} poster="img/ressourceVideo.png"/>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="6" xl="7" className="ml-auto">
                <Filter history={this.props.history} showSaviezVousBtn={true}/>
                  <Row className="mt-3 mt-xl-5">

                    <Col xs="12" xl="1" xl={{order: 3}}>

                      <div id="sticky-social-media-right" className="social-media-widget-right-side">
                      <ul>
                              <li>
                                  <a href={"https://www.facebook.com/sharer/sharer.php?u="+ encodeURI(window.location.href)} target="_blank" class="facebook" title="Partager sur Facebook">
                                      <span className="fab fa-facebook-f" aria-hidden="true"></span>
                                      <span className="sr-only">Partager sur Facebook. Ouvre dans un nouvel onglet.</span>
                                  </a>
                              </li>
                              <li>
                                  <a href={"http://twitter.com/share?text=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;url="+ encodeURI(window.location.href)} class="twitter" target="_blank" title="Partager sur Twitter">
                                      <span className="fab fa-twitter" aria-hidden="true"></span>
                                      <span className="sr-only">Partager sur Twitter. Ouvre dans un nouvel onglet.</span>
                                  </a>
                              </li>
                              <li>
                                  <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + encodeURI(window.location.href) + "&amp;title=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;summary=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;source=LinkedIn"} class="linkedin" target="_blank" title="Partager sur LinkedIn">
                                      <span className="fab fa-linkedin-in" aria-hidden="true"></span>
                                      <span className="sr-only">Partager sur LinkedIn. Ouvre dans un nouvel onglet.</span>
                                  </a>
                              </li>
                              <li>
                                  <a href={"mailto:?subject=J%27enseigne%20en%20fran%C3%A7ais%20au%20canada&amp;body=Visitez%20la%20ressource%20J%27enseigne%20en%20fran%C3%A7ais%20au%20canada%20en%20acc%C3%A9dant%20au%20lien%20suivant%20%3A%20"+  encodeURI(window.location.href)} class="email" target="_blank" title="Partager par courriel">
                                      <span className="fas fa-envelope" aria-hidden="true"></span>
                                      <span className="sr-only">Partager par courriel. Ouvre dans un nouvel onglet.</span>
                                  </a>
                              </li>
                              {/*<li>
                                  <a href="http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fedusourceontario.com%2Fres%2Fmaths-apprendre-enseigner-innover&amp;media=%2F%2Fimages.ctfassets.net%2Fcfektv4t16rw%2F5kcLYdcNyYWrhWRVlm7Kfe%2F46dd6489ee132b4d0dd0929788f3bc21%2Fmath-app-ens-innov.png&amp;description=%C3%89l%C3%A9ments%20fondamentaux%20en%20math%C3%A9matiques" target="_blank" class="pinterest" title="Partager sur Pinterest">
                                      <span class="fab fa-pinterest" aria-hidden="true"></span>
                                      <span class="sr-only">Partager sur Pinterest. Ouvre dans un nouvel onglet.</span>
                                  </a>
                              </li>*/}
                          </ul>
                        </div>
                    </Col>


                    <Col xs="6" md="12" lg="6" xl="5" lg={{order: 1}}>
                      <Card>
                          <Card.Img variant="top" src="img/Accueil_Communico.jpg" alt="Communico"/>
                        <Card.Body>
                          <Card.Text>
                            <p><em>Communico</em> est une autoformation en ligne sur la communication orale dans les écoles de langue française en contextes minoritaires.</p>

                            <ModalComponent
                              content={<p>Le présent lien vous dirigera vers un autre site, extérieur au site <em>J'enseigne en français au Canada</em>.</p>}
                              title="Communico" button="ALLEZ&#x2011;Y"
                              footer={<a href="https://Communico.club/" class="btn btn-primary float-right" target="_blank" title="Site Communico" rel="noopener noreferrer">ALLEZ&#x2011;Y</a>}
                              btnClass="float-right"
                            />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs="6" md="12" lg="6" xl="5" lg={{order: 2}} className="mr-auto mt-md-4 mt-lg-0">
                      <Card>
                          <Card.Img variant="top" src="img/Accueil_ApprocheCulturelle.jpg" alt="Approche culturelle de l'enseignement"/>
                        <Card.Body>
                          <Card.Text>
                            <p>L’autoformation <em>Approche culturelle de l'enseignement</em> permet de mettre les élèves en contact avec la culture francophone et de maintenir ce contact tout au long de leur scolarité.</p>


                            <ModalComponent
                              content={<p>Le présent lien vous dirigera vers un autre site, extérieur au site <em>J'enseigne en français au Canada</em>.</p>}
                              title="Approche culturelle de l'enseignement" button="ALLEZ&#x2011;Y"
                              footer={<a href="http://approcheculturelle.ca/index.php/auth/login" class="btn btn-primary float-right" target="_blank" title="<em>Site Approche culturelle de l'enseignement</em>" rel="noopener noreferrer">ALLEZ&#x2011;Y</a>}
                              btnClass="float-right"
                            />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
              </Col>
            </Row>
            <Row className="scrollDown mt-2" id="scrollDown">
              <Col xs="12" className="text-center mt-0">
                <span className="fas fa-chevron-down" aria-hidden="true" onClick={this.scrollDown}></span>
              </Col>
            </Row>
          </div>





          <div className="DidYouKnow">
            <Row className="scrollUp mb-3" id="scrollUp">
              <Col xs="12" className="text-center">
                <span className="fas fa-chevron-up" aria-hidden="true" onClick={this.scrollUp}></span>
              </Col>
            </Row>
            <Row className="atelier">

              <Col xs="12" className="px-5 px-md-3">
                <a id="LeSaviezVous"></a>
                <h1>Le saviez-vous?</h1>
              </Col>

              <div id="collapseOne" className="card collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div className="card-body">
                  <div id="carouselPlan" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                    <ol className="carousel-indicators" onClick={handleClick}>
                      {this.props.SaviezVous.map((SaviezVous, index) => {
                        return (
                          <li data-target="#carouselPlan" data-slide-to={index} className={index === 0 ? "active" : null}></li>
                        )
                      })}
                    </ol>

                    <div className="carousel-inner">
                      {this.props.SaviezVous.map((SaviezVous, index) => {
                        return (
                          <div className={index === 0 ? "carousel-item active" : "carousel-item"}>
                            <Row>
                              <Col xs="12" md="5" xl="3" className="img">
                                  <img
                                    className="d-block w-100"
                                    src={"img/"+SaviezVous.Image}
                                    alt=""
                                  />
                              </Col>
                              <Col xs="12" md="7" xl="9" className="text pb-3">
                                <div dangerouslySetInnerHTML={{__html: SaviezVous.Texte}}></div>
                                {typeof SaviezVous.Url !== "undefined" && SaviezVous.Url !== "" ?
                                  <a href={SaviezVous.Url} class="float-right btn btn-primary">ALLEZ‑Y</a> : null
                                }
                              </Col>

                            </Row>
                          </div>
                        )
                      })}
                    </div>
                    <a className="carousel-control-prev" href="#carouselPlan" role="button" data-slide="prev" onClick={handleClick}>
                      <span className="prevNextBox"><span className="fas fa-angle-left" aria-hidden="true"></span></span>
                      <span className="sr-only">Précédent</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselPlan" role="button" data-slide="next" onClick={handleClick}>
                      <span className="prevNextBox"><span className="fas fa-angle-right" aria-hidden="true"></span></span>
                      <span className="sr-only">Suivant</span>
                    </a>
                  </div>

                </div>
              </div>


              <div xs="12"><a href="#top" className="my-3 float-right">Retour haut de page</a></div>







{/*
              <Col xs="12" md="6" lg="5" className="px-5 px-md-3">
             <h1>Saviez-vous que...?</h1>
                <div className="mediaBox">
                   <button className="fab fa-facebook-square"></button>
                   <button className="fab fa-twitter-square"></button>
                   <button className="fab fa-linkedin"></button>
                 </div>
                <Row className="DidYouKnowList">
                  {this.props.SaviezVous.map((SaviezVous) => {
                    return (
                      <>
                      <Col xs="3" key={1000+SaviezVous.Id} className="DidYouKnowImgBox">
                        <img src={"img/"+SaviezVous.Image} alt=""></img>
                      </Col>
                      <Col xs="9" key={2000+SaviezVous.Id} className="DidYouKnowParagraphBox"
                          dangerouslySetInnerHTML={{__html: SaviezVous.Texte}}>
                      </Col>
                      </>
                    )
                  })}
                </Row>
              </Col>

              <Col xs="1" lg="1" className="d-none d-lg-block">
              </Col>
*/}


{/*
              <Col xs="12"  className="px-5 px-md-3">
               <h1>Témoignages</h1>
                <div className="mediaBox">
                   <button className="fab fa-facebook-square"></button>
                   <button className="fab fa-twitter-square"></button>
                   <button className="fab fa-linkedin"></button>
                 </div>

                  <Row className="TemoignageList">
                    {this.props.Temoignage.map((Temoignage) => {
                      return (
                        <Col xs="12" key={Temoignage.Id} className="TemoignageBox">
                          <img src={"img/"+Temoignage.Image} alt=""></img>
                            <p>{Temoignage.Texte}</p>
                        </Col>
                      )
                    })}
                  </Row>
              </Col>

*/}
            </Row>
          </div>




        <Footer />
      </>
    );
  }
}






function mapStateToProps(state, ownProps){
    return{
      SaviezVous : state.SaviezVousReducer,
      Temoignage : state.TemoignageReducer
    };
}




export default connect(mapStateToProps)(HomePage);
