import React from "react";
import {Component} from 'react'
import {Row, Col} from 'react-bootstrap';

import CanadaLogo from '../inc/img/Canada.png';


class Footer extends Component {
  render() {
    return (

      <footer className="footer">
        <Row>
          <Col xs="12" lg="6">
            <span>© Le Conseil des ministres de l'Éducation (Canada) 2019 – Tous droits réservés.</span>
          </Col>
          <Col xs="12" lg="6" className="d-flex">
            <span>Cette ressource a été élaborée par le Consortium du français langue première du Conseil des ministres de l’Éducation (Canada), avec le soutien financier des provinces et territoires membres et du gouvernement du Canada.</span>
            <div className="logoCanada"><img src={CanadaLogo} alt="Logo CMEC" /></div>
          </Col>
        </Row>
      </footer>
    );
  }
}
export default Footer;
