import React from "react";
import {Component} from 'react'



class AblePlayerComponent extends Component {


  createDescriptionBox(){
    if (this.props.videoDescription){
      return(
        <>
        <button class="btn btnNoir w-100 text-left" type="button" data-toggle="collapse" data-target="#transcriptionVideo" aria-expanded="false" aria-controls="transcriptionVideoIntro">
          <span class="fas fa-chevron-right mr-2"></span>Description de la vid&eacute;o</button>
        <div id="transcriptionVideo" class="boiteTranscriptionVideo collapse"
          dangerouslySetInnerHTML={{__html: this.props.videoDescription}}>
        </div>
        </>
      )
    }
  }


  render() {
    return (
      <div>
  			<video id="videoPlayer" data-able-player poster={this.props.poster}>
  			  <source type="video/mp4" src={this.props.src}/>
          <track kind="captions" src={this.props.captionSrc}/>
  			</video>
          {this.createDescriptionBox()}
  		</div>
    );
  }
}


export default AblePlayerComponent;
